import React, { useState } from 'react'
import Alert from './common/Alert';
import callMyApi from '../../ApiCaller';
import { validEmail, validMessage, validName, validSubject } from '../../Rejex';
import Loader  from './common/Loader';


export default function ContactUs() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertMessageType, setAlertMessageType] = useState("");
  const [showAlart, setShowAlert] = useState(false);
  const [alertCategory, setAlartCategory] = useState(null);
  const[showSpinner,setShowSpinner]=useState(false)
      
  //const [emailErr, setEmailErr] = useState(false);

  let formErrors = [];

  function validate() {
    let status = false;

    if (!validName.test(name)) {
      console.log('invalid name')
      formErrors.push('Name must be between 4 to 30 charectors.');
      status = true;
    }
    if (!validEmail.test(email)) {
      formErrors.push('Invalid email id format.');
      status = true;
    }
    if (!validSubject.test(subject)) {
      console.log('invalid name')
      formErrors.push('Subject must be between 5 to 50 charectors.');
      status = true;
    }
    if (!validMessage.test(message)) {
      console.log('invalid name')
      formErrors.push('Messsage must be between 5 to 250 charectors.');
      status = true;
    }
    return status;
  }

  const updateShowAlert = (props) => {
    setShowAlert(false)
  }

  async function sendMessage() {

    let validateError = validate();
    if (validateError) {

      setShowAlert(true);
      setAlertMessage(formErrors);
      setAlertMessageType("danger");
      setAlartCategory("Error");

    } else {
      let data = { name, email, subject, message }
      //var res = await callMyApi('general/api/contactus/', data, 'POST');
      setShowSpinner(true);
      var res = await callMyApi('general/api/contactus/', data, 'POST')
        .then(data => {
          console.log(JSON.stringify(data) + 'ddd');
          console.log('success');
          console.log(JSON.stringify(data) + 'ddd');
          setShowAlert(true);
          setAlertMessageType('success');
          setAlertMessage('Thank you ' + name + ' for your message. It has been sent.')

        }).catch(e => {
          console.log(e)
          console.log('danger');
          setShowAlert(true);
          setAlertMessageType('danger');
          setAlertMessage('Something went wrong, Please try again later.')
        });
        setShowSpinner(false)



      //  if (res.status == "201") {
      //    setShowAlert(true);
      //    setAlertMessageType('success');
      //    setAlertMessage('Thank you ' + res.body.name + ' for your message. It has been sent.')
      //  } else {
      //    setShowAlert(true);
      //    setAlertMessageType('danger');
      //    setAlertMessage('Something went wrong, Please try again later.')
      //  }
      //  setName("");
      //  setEmail("");
      //  setMessage("");
      //  setSubject("");

    }


  }
  return (
    <div>
      {showSpinner?<Loader/>:""}
      
      {/* Contact Start */}
      <div id="contact" className="container-xxl py-4">
        <div className="container">
          <div className="row g-5 py-5">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3 display-5">Let's Talk!</div>
              <h1 className="display-6 mb-5">If You Have Any Query, Please Contact Us</h1>
              <p className="mb-4">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Porro vel nam numquam dolor! Tempore ipsam et voluptatem cum commodi expedita ullam ctetur! Libero aliquam earum ipsum unde dolorum.  <a href="https://htmlcodex.com/contact-form" target='_blank'>Download Now</a>.</p>

              <div className="row g-3">
                <div className="col-md-6">
                  <div className="form-floating">
                    <input type="text" className="form-control" id="name" onChange={e => setName(e.target.value)} placeholder="Your Name" />
                    <label htmlFor="name">Your Name</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input type="email" className="form-control" id="email" onChange={e => setEmail(e.target.value)} placeholder="Your Email" />
                    <label htmlFor="email">Your Email</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <input type="text" className="form-control" id="subject" onChange={e => setSubject(e.target.value)} placeholder="Subject" />
                    <label htmlFor="subject">Subject</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <textarea className="form-control" placeholder="Leave a message here" id="message" onChange={e => setMessage(e.target.value)} style={{ height: 100 }} defaultValue={""} />
                    <label htmlFor="message">Message</label>
                  </div>
                </div>
                <div className="col-12">
                  <button className="btn btn-primary py-2 px-3 me-3" onClick={sendMessage}>
                    Send Message
                    <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                      <i className="fa fa-arrow-right" />
                    </div>
                  </button>
                </div>
                {/* {validateStatus ? "invalid mail" : "valid mail"} */}
                {<Alert showAlart={showAlart} type={alertMessageType} userMessage={alertMessage} updateShowAlert={updateShowAlert} alertCategory={alertCategory} />}

              </div>

            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s" style={{ minHeight: 450 }}>
              <div className="position-relative rounded overflow-hidden h-100">
                <iframe className="position-relative w-100 h-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4407239321035!2d80.86307987521806!3d25.188355632059157!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3984a747d7d5bd73%3A0x52a25e74316a9ff2!2sISKCON%20Chitrakoot!5e0!3m2!1sen!2sin!4v1684489258187!5m2!1sen!2sin" frameBorder={0} style={{ minHeight: 450, border: 0 }} allowFullScreen aria-hidden="false" tabIndex={0} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
            {/* {<Alert showAlart={showAlart} type={alertMessageType} userMessage={alertMessage} />} */}

          </div>
        </div>
      </div>
      {/* Contact End */}

    </div>
  )
}
