
import React, { Component } from 'react'
import Footer from '../../components/general/common/Footer'
import { NavMenu } from '../../components/general/common/NavMenu'
import { Link } from 'react-router-dom'
export default class ChaitanyaMahaprabhu extends Component {
    render() {
        return (
            <div>
                <NavMenu />

                {/* Page Header Start */}
                <div className="container-fluid page-header mb-5 wow fadeIn" style={{ background: "linear-gradient(rgba(0, 29, 35, .8), rgba(0, 29, 35, .8)), url('../../img/chaitanya/sri_chaitanya.jpeg') center center no-repeat", backgroundSize: "cover" }} data-wow-delay="0.1s">
                    <div className="container text-center">
                        <h1 className="display-4 text-white animated slideInDown mb-4">Lord Shri Chaitanya Mahaprabhu</h1>
                        <nav aria-label="breadcrumb animated slideInDown">
                            <ol className="breadcrumb justify-content-center mb-0">
                                <li className="breadcrumb-item"><Link className="text-white" to="#">Home</Link></li>
                                <li className="breadcrumb-item"><Link className="text-white" to="#">About</Link></li>
                                <li className="breadcrumb-item text-primary active" aria-current="page">ChaitanyaMahaprabhu</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                {/* Page Header End */}

                <div className="container-xxl py-3">
                    <div className="container">
                        <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{}}>
                            <div className="row bg-light border-2 border-primary rounded p-4 mb-4" style={{ border: " dashed" }}>
                                <div className='col-sm-7'>
                                    {/* <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3 display-5">About Chitrakoot</div> */}
                                    <p align="justify" >
                                        Chaitanya Mahaprabhu was born in Mayapur in the town of Nadia, just after sunset on the evening of the 23rd Falgun, 1407 Advent of Sakabda answering to the 18th February, 1486 of the Christian era. The moon was eclipsed at the time of His birth and people of Nadia were then engaged, as usual on such occasions, in bathing in the Bhagirathi with loud cheers of Haribol. His father Jagannath Misra was a poor Brahmin of the Vedic order, and His mother Sachi Devi was a model good woman, both descended from Brahmin stocks originally residing in Sylhet. Mahaprabhu was a beautiful Child and the ladies of the town came to see Him with presents. His mother's father. Pandit Nilambar Chakravarti, a renowned astrologer, foretold that the Child -would be a great Personage in time; and he therefore, gave Him the name Visvambhar. The ladies of the neighborhood styled Him Gaur Hari on account of His golden complexion, and His mother called Him Nimai on account of the Nim tree near which He was born. Most of His contemporary biographers have mentioned certain anecdotes regarding Sri Chaitanya...
                                        <br />
                                        Most of His contemporary biographers have mentioned certain anecdotes regarding Sri Chaitanya which are simple records of His early miracles. It is said that, when He was an infant in His mother's arms, He wept continually, and when the neighbouring ladies and His mother cried Haribol, be used to stop. Thus there was a continuation of the utterance of Haribol in the house, foreshowing the future mission of the Hero. It has also been stated, that, when His mother once gave Him sweetmeats to eat, He ate clay instead of the food. His mother asking for the reason, He stated that, as every sweetmeat was nothing but clay transformed, He could eat clay as well. His mother who was also the consort of a Pandit explained, that every article in a special state was adapted to a special use. Earth, while in the state o a jug, could be used as a water-pot, but in the state of a brick such a use was not possible. Clay, therefore, in the form of sweetmeats was usable as food and not clay in its other states. The Lad was convinced and admitted His stupidity in eating clay and agreed to avoid the mistake in future. </p>
                                </div>
                                <div className='col-sm-5'>
                                    <div className="position-relative h-100">
                                        <img className="rounded w-100 h-100" src="../../img/chaitanya/sri_chaitanya.1.jpeg" style={{ objectFit: 'cover', maxHeight: '600px' }} alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" align="justify">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">

                                            Lord Krishna left the Bhagavad Gita with us
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">

                                            <div class="row">
                                                <div class="col-sm-4">

                                                    Lord Krishna gave us Bhagavad Gita 5000 years ago which includes timeless wisdom of Bhakti & Devotion. He gave Bhagavd Gita and left the planet. Humans got the instructions but how to follow these in our day to day life was the biggest challenge. Lot of concocted sampradayas came wrongfully interpreting the teachings of Lord Krishna.



                                                    Seeing the situation and following the prayers of pure Vaishanavas like Advaita Acharya, Krishna decided to come himself in the garb of a devotee to teach people on how to follow Bhagavad Gita in their life and be successful in all the manners. He is Lord Chaitanya also called “Gauranga” being in golden body complexion. His advent is mentioned in various scriptures, few of them are mentioned below

                                                </div>
                                                <div class="col-sm-4 my-1">
                                                    <div className="position-relative h-100">
                                                        <img className="rounded w-100 h-100" src="../../img/chaitanya/sc1.webp" style={{ objectFit: 'cover',maxHeight:"350px" }} alt />
                                                    </div>
                                                </div>
                                                <div class="col-sm-4 my-1">
                                                    <div className="position-relative h-100">
                                                        <img className="rounded w-100 h-100" src="../../img/chaitanya/sri_chaitanya.1.jpeg" style={{ objectFit: 'cover',maxHeight:"350px" }} alt />
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">

                                            Scriptural References of Lord Caitanya
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="row">
                                                <div class="col-sm-4">
                                                    <div className="position-relative h-100">
                                                        <img className="rounded w-100 h-100" src="../../img/chaitanya/sc2.webp" style={{ objectFit: 'cover', maxHeight:"300px" }} alt />
                                                    </div>
                                                </div>
                                                <div class="col-sm-8">
                                                    <p>
                                                    <strong> 1. Srimad-Bhagavatam (11.5.32)</strong><br/> 
                                                        “krishna-varnam tvishakrishnam sangopangastra-parshadam
                                                        yajnaih sankirtana-prayair yajanti hi sumedhasah”
                                                        In the age of Kali, intelligent persons perform congregational chanting to worship the incarnation of Godhead who constantly sings the holy name of Krishna. Although His complexion is not blackish, He is Krishna Himself. He is accompanied by His associates, servants, weapons and confidential companions.
                                                    </p>
                                                    <p> 
                                                    <strong> 2. Garuda Purana</strong><br/>
                                                        The Supreme Lord says:
                                                        “aham purno bhavisyami yuga-sandhyau visesatah
                                                        mayapure navadvipe bhavisyami saci-sutah”
                                                        In the first part of Kali-yuga, I will appear in my complete spiritual form in Mayapura, Navadvipa and become the son of Shachi.
                                                    </p> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        The most munificient incarnation of God
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="row">
                                                <div class="col-sm-8">
                                                       <p>
                                                       Lord Chaitanaya freely distributed love of Godhead &ldquo;Krishna Prema&rdquo; to anyone and everyone without considering cast, creed or qualification. His mercy knows no bounds. He is Lord Krishna himself in the mood of Srimati Radharani so he is also called combined form of Radha and Krishna. He is more merciful than all other forms of Lord. Inline in other incarnations of Lord, he does not kill sinners but he kills their demoniac mentality by surcharging them with pure love of Godhead.
                                                    </p>
                                                   </div>
                                                <div class="col-sm-4">
                                                    <div className="position-relative h-100">
                                                        <img className="rounded w-100 h-100" src="../../img/chaitanya/sc3.webp" style={{ objectFit: 'cover', maxHeight:"300px" }} alt />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingFour">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Lord Chaitnya and Holy names
                                        </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="row">
                                                <div class="col-sm-4">
                                                    <div className="position-relative h-100">
                                                        <img className="rounded w-100 h-100" src="../../img/chaitanya/sc4.webp" style={{ objectFit: 'cover', maxHeight:"300px" }} alt />
                                                    </div>
                                                </div>
                                                <div class="col-sm-8">
                                                    <p >
                                                    He appeared during lunar eclipse, during which in those times, people used to chant holy names of Krishna while standing in Ganges river. His advent happened when people all over were chanting loudly holy names of Lord. Even when he was a baby, he used to not stop crying until he hears the holy names. His childhood is filled with nectarian and wonderful pastimes. Along with attachment to holy names of Lord, Sri Chaitanya also was a great scholar of Vedic wisdom and was called &ldquo;Nimai Pandit&rdquo; among the residents of Navadvipa. He gave the Hare Krishna Mahamantra to us (people living in the age of Kaliyuga) as the most effective and easy method to realize God.
                                                    
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
