//import { Counter } from "./components/Counter";
import React from "react";
import { FetchData } from "./components/general/FetchData";
import SignIn from './pages/general/SignIn';
import Logout from './components/general/Logout';
import Courses from './pages/general/Courses';
import { Home } from "./pages/general/Home";
import Dashboard from "./pages/admin/Dashboard";
import CoursesRegister from "./pages/general/CoursesRegister";
import Activities from "./pages/general/Activities"
import Chitrakoot from "./pages/general/Chitrakoot"
import Kamadgiri from "./pages/general/Kamadgiri"
import Prabhupad from "./pages/general/Prabhupad"
import ChaitanyaMahaprabhu from "./pages/general/ChaitanyaMahaprabhu"
import Igallery from "./pages/general/Igallery"
import Vgallery from "./pages/general/Vgallery"
import ContactUs from "./pages/general/ContactUs";
import AContactUs from './pages/admin/ContactUs'
import AEmail from './pages/admin/Email'
import ANotification from './pages/admin/Notification'
import ASlider from './pages/admin/Slider'
import ACourses from './pages/admin/Courses'
import AActivities from './pages/admin/Activities'
import AImageGallery from './pages/admin/ImageGallery'
import AVideoGallery from './pages/admin/VideoGallery'
import ARegistrations from './pages/admin/Registrations'
import {
  createBrowserRouter,
} from "react-router-dom";
const AppRoutes =createBrowserRouter([
  {
    index: true,
    element: <Home />,
    errorElement:<div>No Pages found</div>
   },
   {
    path: '/home',
    element: <Home />
   },
  {
    path: '/signin',
    element: <SignIn />
    },
    {
        path: '/logout',
        element: <Logout />
    },
    {
      path: '/activities',
      element: <Activities />
  },
  {
    path: '/contactus',
    element: <ContactUs />
},
  {
    path: '/courses',
    element: <Courses />
  },
  
  {
    path: '/coursesregister',
    element: <CoursesRegister />
  },
  {
    path: '/chitrakoot',
    element: <Chitrakoot />
  },
  {
    path: '/kamadgiri',
    element: <Kamadgiri />
  },
  {
    path: '/prabhupad',
    element: <Prabhupad />
  },
  {
    path: '/chaitanyamahaprabhu',
    element: <ChaitanyaMahaprabhu />
  },
  {
    path: '/igallery',
    element: <Igallery />
  },
  {
    path: '/vgallery',
    element: <Vgallery />
  },
  {
    path: '/admin',
    element: <Dashboard />
  },
  {
    path: '/admin/dashboard',
    element: <Dashboard />
  },
  {
        path: '/admin/contactus',
        element: <AContactUs />
  },
  {
        path: '/admin/notification',
      element: <ANotification />
    },
    {
        path: '/admin/email',
        element: <AEmail />
    },
    {
        path: '/admin/slider',
        element: <ASlider />
    },
    {
      path: '/admin/courses',
      element: <ACourses />
    },
    {
      path: '/admin/activities',
      element: <AActivities />
    },
    {
      path: '/admin/igallery',
      element: <AImageGallery />
    },
    {
      path: '/admin/vgallery',
      element: <AVideoGallery />
    },
    {
      path: '/admin/registrations',
      element: <ARegistrations />
    },
    {
      path: '/fetch-data',
      element: <FetchData />
   }
]);

const SignInRoutes = [
  {
    path: '/signin',
    element: <SignIn />
  }
];


export default AppRoutes;
// export { SignInRoutes };
