export const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
export const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');
export const validName = new RegExp('^[a-zA-Z0-9 ]{4,50}$');
export const validSubject = new RegExp('^.{5,50}$');
export const validMessage = new RegExp('^.{10,250}$');
export const validComment = new RegExp('^.{5,500}$');
export const validAddress = new RegExp('^.{10,350}$');
export const validMobno = new RegExp('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$');
export const validLanguage = new RegExp('^.{3,18}$');
export const validUrl= new RegExp('^(https?:\\/\\/)?((([-a-z0-9]{1,63}\\.)*?[a-z0-9]([-a-z0-9]{0,253}[a-z0-9])?\\.[a-z]{2,63})|((\\d{1,3}\\.){3}\\d{1,3}))(:\\d{1,5})?((\\/|\\?)((%[0-9a-f]{2})|[-\\w\\+\\.\\?\\/@~#&=])*)?$');
export const validNotification = new RegExp('^.{10,500}$');