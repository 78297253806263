import React, { useState } from 'react'
import { json, useNavigate } from "react-router-dom"
import Alert from './common/Alert';
import callMyApi from '../../ApiCaller';
import Loader from './common/Loader';
import { Link } from 'react-router-dom'
export default function Login(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [alertMessage, setAlertMessage] = useState("");
    const [alertMessageType, setAlertMessageType] = useState("");
    const [showAlart, setShowAlert] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false)




    const updateShowAlert = (props) => {
        setShowAlert(false)
    }
    async function login() {
        let data = { email, password }
        // var res= await callMyApi('general/api/login/', data,'POST');

        setShowSpinner(true);
        await callMyApi('general/api/login/', data, 'POST')
            .then(data => {
                //console.log(data);
                if (data.userMessage== "Authentication successful.") {
                    localStorage.setItem('login-info', JSON.stringify(data));
                    // setAlertMessageType('success');
                    // setAlertMessage(data.userMessage)
                    navigate('/admin');
                } else {
                    setShowAlert(true);
                    setAlertMessageType('danger');
                    setAlertMessage(data.userMessage);
                    console.log(data.userMessage);
                }
            }).catch(e => {
                console.log('danger');
                console.log(e)
                setShowAlert(true);
                setAlertMessageType('danger');
                setAlertMessage('Something went wrong, Please try again later.')

            });
        setShowSpinner(false)

        // setResponse(res.body);
        // //console.log(res.status);

        // console.log(res.body)
        // // console.log(JSON.stringify(res));

        // if (res.status =="200" && res.body.userMessage == "Authentication successful.") {
        //     localStorage.setItem('login-info', JSON.stringify(res.body));
        //     //console.log("success");
        //     navigate('/admin');
        // } else if(res.body.userMessage == "Invalid user id or password.") {
        //     setShowAlert(true);
        //     console.log("invalid");
        // }

    }
    return (
        <div>

            {showSpinner ? <Loader /> : ""}
            {/* login Start */}

            <div className="col-lg-3 " ></div>
            <div id="donate" className="container-fluid  my-5 py-5" data-parallax="scroll" >
                <div className="container py-5" >
                    <div className="row g-5 align-items-center" >
                        <div className="col-lg-3 "></div>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s" >
                            <div className='row bg-dark py-4 px-3' style={{ borderRadius: "13px", border: "5px solid #FF6F0F", boxShadow: "0px 0px 30px 10px black" }}>
                                <div className="col-lg-4 col-md-4 col-sm-2 col-4"></div>
                                <div className="col-lg-4 col-md-4 col-sm-2 col-4 bg-light my-3 wow fadeInUp" data-wow-delay="0.1s" style={{ borderRadius: "10px" }} >
                                    <div className="service-item  text-center h-100 p-1 p-xl-2">
                                        <img className="img-fluid img-responsive" src="img/krishna.png" style={{ maxHeight: "150px", maxWidth: "150px", margin: "0 auto" }} alt="krishna-img" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-2 col-4"></div>
                                <div className="h-100 bg-dark "  >
                                    <div className="row g-3">

                                        {<Alert showAlart={showAlart} type={alertMessageType} userMessage={alertMessage} updateShowAlert={updateShowAlert} />}


                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control bg-light border-0" id="name" placeholder="Your Email" onChange={e => setEmail(e.target.value)} required />
                                                <label htmlFor="email">Your User ID</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input type="password" className="form-control bg-light border-0" id="password" placeholder="Your Password" onChange={e => setPassword(e.target.value)} required />
                                                <label htmlFor="password">Your Password</label>
                                            </div>
                                        </div>
                                        <Link className="btn-link text-white-50 " to="#" >
                                            Forget Password...?</Link>
                                        <div className="col-12 text-center">
                                            <button onClick={login} className="btn btn-outline-primary py-2 px-5 text-center" style={{ fontSize: "22px" }}>
                                                LOGIN
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div>
                        <div className="col-lg-3 "></div>
                    </div>
                </div>
            </div>
            {/* login End */}

        </div>
    )
}



