import React, { Component } from 'react';
import AppRoutes from './AppRoutes';
import AdminRoutes from './AppRoutes';
import { Layout } from './components/general/Layout';
import SignIn from './pages/general/SignIn';
//import './custom.css';
// import '../public/assets/css/bootstrap.min.css';
// import '../public/assets/css/style.css'
import {
  RouterProvider,
} from "react-router-dom";

export default function App(){
  // static displayName = App.name;

  // render() {
    return (
        <RouterProvider router={AppRoutes} />
    );
  // }
}
