import { useState ,Fragment,useEffect} from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Link } from "react-router-dom";
import { images, CustomImage } from "./images";
import Footer from '../../components/general/common/Footer'
import {NavMenu} from '../../components/general/common/NavMenu'
import ReactPlayer from 'react-player'
import VideoThumbnail from 'react-video-thumbnail';
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, XCircleIcon} from '@heroicons/react/24/outline'
import callMyApi from '../../ApiCaller';

// const videoLinkData0=[
//   {
//       "id": "1",
//       "title": "Big Buck Bunny",
//       "thumbnailUrl": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/Big_Buck_Bunny_thumbnail_vlc.png/1200px-Big_Buck_Bunny_thumbnail_vlc.png",
//       "duration": "8:18",
//       "uploadTime": "May 9, 2011",
//       "views": "24,969,123",
//       "author": "Vlc Media Player",
//       "videoUrl": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
//       "description": "Big Buck Bunny tells the story of a giant rabbit with a heart bigger than himself. When one sunny day three rodents rudely harass him, something snaps... and the rabbit ain't no bunny anymore! In the typical cartoon tradition he prepares the nasty rodents a comical revenge.\n\nLicensed under the Creative Commons Attribution license\nhttp://www.bigbuckbunny.org",
//       "subscriber": "25254545 Subscribers",
//       "isLive": true
//   },
//   {
//       "id": "2",
//       "title": "The first Blender Open Movie from 2006",
//       "thumbnailUrl": "https://i.ytimg.com/vi_webp/gWw23EYM9VM/maxresdefault.webp",
//       "duration": "12:18",
//       "uploadTime": "May 9, 2011",
//       "views": "24,969,123",
//       "author": "Blender Inc.",
//       "videoUrl": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
//       "description": "Song : Raja Raja Kareja Mein Samaja\nAlbum : Raja Kareja Mein Samaja\nArtist : Radhe Shyam Rasia\nSinger : Radhe Shyam Rasia\nMusic Director : Sohan Lal, Dinesh Kumar\nLyricist : Vinay Bihari, Shailesh Sagar, Parmeshwar Premi\nMusic Label : T-Series",
//       "subscriber": "25254545 Subscribers",
//       "isLive": true
//   },
//   {
//       "id": "3",
//       "title": "For Bigger Blazes",
//       "thumbnailUrl": "https://i.ytimg.com/vi/Dr9C2oswZfA/maxresdefault.jpg",
//       "duration": "8:18",
//       "uploadTime": "May 9, 2011",
//       "views": "24,969,123",
//       "author": "T-Series Regional",
//       "videoUrl": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
//       "description": "Song : Raja Raja Kareja Mein Samaja\nAlbum : Raja Kareja Mein Samaja\nArtist : Radhe Shyam Rasia\nSinger : Radhe Shyam Rasia\nMusic Director : Sohan Lal, Dinesh Kumar\nLyricist : Vinay Bihari, Shailesh Sagar, Parmeshwar Premi\nMusic Label : T-Series",
//       "subscriber": "25254545 Subscribers",
//       "isLive": true
//   },
//   {
//       "id": "4",
//       "title": "For Bigger Escape",
//       "thumbnailUrl": "https://i.ytimg.com/vi/v-icNVDbVLk/hqdefault.jpg?s…AFwAcABBg==&rs=AOn4CLDPbeaku8SQMtrissPa5stIjCuHFQ",
//       "duration": "8:18",
//       "uploadTime": "May 9, 2011",
//       "views": "24,969,123",
//       "author": "T-Series Regional",
//       "videoUrl": "https://www.youtube.com/watch?v=vBJ5HSs-N9o&list=RDsVjse0tE980&index=7",
//       "description": " Introducing Chromecast. The easiest way to enjoy online video and music on your TV—for when Batman's escapes aren't quite big enough. For $35. Learn how to use Chromecast with Google Play Movies and more at google.com/chromecast.",
//       "subscriber": "25254545 Subscribers",
//       "isLive": false
//   },
//   {
//       "id": "5",
//       "title": "Big Buck Bunny",
//       "thumbnailUrl": "https://i.ytimg.com/vi/v-icNVDbVLk/hqdefault.jpg?s…AFwAcABBg==&rs=AOn4CLDPbeaku8SQMtrissPa5stIjCuHFQ",
//       "duration": "8:18",
//       "uploadTime": "May 9, 2011",
//       "views": "24,969,123",
//       "author": "Vlc Media Player",
//       "videoUrl": "https://www.youtube.com/watch?v=pxCWiYFkvTg&list=RDsVjse0tE980&index=6",
//       "description": "Big Buck Bunny tells the story of a giant rabbit with a heart bigger than himself. When one sunny day three rodents rudely harass him, something snaps... and the rabbit ain't no bunny anymore! In the typical cartoon tradition he prepares the nasty rodents a comical revenge.\n\nLicensed under the Creative Commons Attribution license\nhttp://www.bigbuckbunny.org",
//       "subscriber": "25254545 Subscribers",
//       "isLive": true
//   },
//   {
//       "id": "6",
//       "title": "For Bigger Blazes",
//       "thumbnailUrl": "https://i.ytimg.com/vi/v-icNVDbVLk/hqdefault.jpg?s…AFwAcABBg==&rs=AOn4CLDPbeaku8SQMtrissPa5stIjCuHFQ",
//       "duration": "8:18",
//       "uploadTime": "May 9, 2011",
//       "views": "24,969,123",
//       "author": "T-Series Regional",
//       "videoUrl": "https://www.youtube.com/watch?v=miJXSrm3gz4&list=RDsVjse0tE980&index=3",
//       "description": "Song : Raja Raja Kareja Mein Samaja\nAlbum : Raja Kareja Mein Samaja\nArtist : Radhe Shyam Rasia\nSinger : Radhe Shyam Rasia\nMusic Director : Sohan Lal, Dinesh Kumar\nLyricist : Vinay Bihari, Shailesh Sagar, Parmeshwar Premi\nMusic Label : T-Series",
//       "subscriber": "25254545 Subscribers",
//       "isLive": false
//   },
//   {
//       "id": "7",
//       "title": "For Bigger Escape",
//       "thumbnailUrl": "https://img.jakpost.net/c/2019/09/03/2019_09_03_78912_1567484272._large.jpg",
//       "duration": "8:18",
//       "uploadTime": "May 9, 2011",
//       "views": "24,969,123",
//       "author": "T-Series Regional",
//       "videoUrl": "https://www.youtube.com/watch?v=sVjse0tE980&list=RDsVjse0tE980&start_radio=1",
//       "description": " Introducing Chromecast. The easiest way to enjoy online video and music on your TV—for when Batman's escapes aren't quite big enough. For $35. Learn how to use Chromecast with Google Play Movies and more at google.com/chromecast.",
//       "subscriber": "25254545 Subscribers",
//       "isLive": true
//   },
//   {
//       "id": "8",
//       "title": "The first Blender Open Movie from 2006",
//       "thumbnailUrl": "https://i.ytimg.com/vi_webp/gWw23EYM9VM/maxresdefault.webp",
//       "duration": "12:18",
//       "uploadTime": "May 9, 2011",
//       "views": "24,969,123",
//       "author": "Blender Inc.",
//       "videoUrl": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
//       "description": "Song : Raja Raja Kareja Mein Samaja\nAlbum : Raja Kareja Mein Samaja\nArtist : Radhe Shyam Rasia\nSinger : Radhe Shyam Rasia\nMusic Director : Sohan Lal, Dinesh Kumar\nLyricist : Vinay Bihari, Shailesh Sagar, Parmeshwar Premi\nMusic Label : T-Series",
//       "subscriber": "25254545 Subscribers",
//       "isLive": false
//   }
// ]
export default function App() {
  const [showSpinner, setShowSpinner] = useState(false);
  const [data, setData] = useState([]);
  const [page_size, setPaze_size] = useState(15);
  const [current_page, setCurrent_paze] = useState(1);

  async function getData() {
    setShowSpinner(true);
    await callMyApi('admin/api/vgallery?page=' + current_page + '&page_size=' + page_size, 'GET')
      .then(res => {
        if (data) {
          setData(data.concat(res.record_per_page));
         
          setShowSpinner(false)
        } else {
          console.log('Unable to get notification.')
        }
      }).catch(e => {
        console.log('Something went wrong, Please try again later.')
      });
  }
  const loadMore = () => {
    setPaze_size(page_size+ 15)
    setCurrent_paze(current_page + 1)
    }
  useEffect(() => {
    getData();
  }, [page_size, current_page]);
  var videoLinkData = []
  for (let i = 0; i < data.length; i++) {
    let obj = data[i];
    videoLinkData[i] = {  
      // "id": (i+1).toString(),
      // "title": obj.title,
      // "thumbnailUrl": obj.videoLink+'??origin=https://youtube.com',
      // "duration": "12:18",
      // "uploadTime": "May 9, 2011",
      // "views": "24,969,123",
      // "author": "Blender Inc.",
      // "videoUrl": obj.videoLink,
      // "description": obj.description,
      // "subscriber": "25254545 Subscribers",
      // "isLive": false,

      "id": (i+1).toString(),
      "title": obj.title,
      "thumbnailUrl": obj.videoLink,
      "duration": "8:18",
      "uploadTime": "May 9, 2011",
      "views": "24,969,123",
      "author": "Vlc Media Player",
      "videoUrl": "https://www.youtube.com/watch?v=sVjse0tE980&list=RDsVjse0tE980&start_radio=1",
      "description": "Big Buck Bunny tells the story of a giant rabbit with a heart bigger than himself. When one sunny day three rodents rudely harass him, something snaps... and the rabbit ain't no bunny anymore! In the typical cartoon tradition he prepares the nasty rodents a comical revenge.\n\nLicensed under the Creative Commons Attribution license\nhttp://www.bigbuckbunny.org",
      "subscriber": "25254545 Subscribers",
      "isLive": true
    }
  }
  console.log(videoLinkData)
//console.log(videoLinkData0)

  const [index, setIndex] = useState(-1);
  const [selectVideo,setSelectVideo]=useState({});
  const currentImage = images[index];
  const [openModal, setOpenModal] = useState(false)
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;
  // const handleClick = (index: number, item: CustomImage) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);
  const handleCloseModal=()=>{
    setOpenModal(false); 
    setSelectVideo({});
  }
  const handleOpenModal =(e,videoList)=>{
    setSelectVideo({...videoList});
    setOpenModal(true);
  }
  useEffect(()=>{
 
  },[selectVideo])
  return (
    <div>
        <NavMenu/>


   {/* Page Header Start */}
   <div className="container-fluid page-header mb-5 wow fadeIn" style={{background: "linear-gradient(rgba(0, 29, 35, .8), rgba(0, 29, 35, .8)), url('../../img/ckt/kamadgiri.jpeg') center center no-repeat", backgroundSize:"cover"}} data-wow-delay="0.1s">
  <div className="container text-center">
    <h1 className="display-4 text-white animated slideInDown mb-4">Video Gallery</h1>
    <nav aria-label="breadcrumb animated slideInDown">
      <ol className="breadcrumb justify-content-center mb-0">
        <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
        <li className="breadcrumb-item"><a className="text-white" href="#">Gallery</a></li>
        <li className="breadcrumb-item text-primary active" aria-current="page">Video Gallery</li>
      </ol>
    </nav>
  </div>
</div>
{/* Page Header End */}
{data && data.length>0 ? <>
<div className="container-xxl py-3">
  <h1 className="text-primary text-center">Development in progress.</h1>
        <div className="container" style={{display:"block"}}>
     <div className="container-xxl py-3"style={{display:"block"}}>
        <div className="container">
      <div className="container text-center"style={{display:"block"}}>
      <div className="row"style={{display:"block"}}>
      {videoLinkData.map((videoList,index)=>{
        return <div key={index} style={{display:"block"}} className=" w-full cursor-pointer my-3" onClick={(e)=>handleOpenModal(e,videoList)}><VideoThumbnail
    videoUrl={videoList.videoUrl}
    thumbnailHandler={(thumbnail) => console.log(thumbnail)}
    /></div>
      })}</div></div>
      </div></div>
     </div>
     {/* Start Modal for video  */}
     {selectVideo && <div>

      <Transition.Root show={openModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={(e)=>console.log(e)} onClose={handleCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-2 pb-4 pt-3 sm:p-4 sm:pb-4 h-full w-full">
                  <div className="sm:flex sm:items-start cursor-pointer">
                    <div className="text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {selectVideo.title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {/* {selectVideo.description} */}
                        </p>
                      </div>
                      <div className="sm:flex sm:items-center justify-center items-center align-middle h-full">
                    <ReactPlayer url={selectVideo.videoUrl} loop={false} controls={true} height={"auto"} width={"95%"}/>
                    </div>
                    </div>
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10" onClick={handleCloseModal}>
                      <XCircleIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                    </div>
                  </div>
                </div>
                {/* <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={handleCloseModal}
                  >
                    Deactivate
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpenModal(false)}
                  >
                    Cancel
                  </button>
                </div> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

     </div>}
     {/* End Modal for video */}
      </div> <center><button  className="btn btn-primary py-2 px-3 me-3" to="#" onClick={loadMore}>
        View More
        <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
          <i className="fa fa-arrow-right" />
        </div>
      </button></center></>:
      <><img style={{maxHeight:"300px"}}className="mx-auto" src="../../img/no data.png" /><h3 className="text-center text-2xl text-primary pb-5">No Records Found</h3></>
      }
      <Footer/>
    </div>
  );
}
{/* <ReactPlayer url={videoList.videoUrl} loop={true} controls={true} playsinline={true} /> */}
