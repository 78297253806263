import React from "react";
import Slider from "react-slick";

export default function SimpleSlider() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <Slider {...settings}>
            <div className="testimonial-item  border rounded  pt-5" >
                <img className="img-fluid bg-light rounded-circle p-2 mx-auto mb-1" src="../../img/t1.webp" style={{ width: 250, height: 250 }} />
                <div className="testimonial-text rounded text-center p-4">
                    <h3 className="mb-1">Ananta Dasi </h3>
                    <h5 className="fst-italic mb-3">Profession</h5>

                    <p align="center" style={{ textAlign: "justify" }}>
                        Ananta Dasi, Gaura Sundara Dasa, Govinda Dasi, Kirtanananda Dasa, Rayarama Dasa, Srila Prabhupada, behind Srila Prabhupada is Brahmananda Dasa, Hamsadutta Dasa, Himavati Dasi, Damodara Dasa, Advaita Dasa and Purushottama Dasa.
                        Right before this picture was taken they had all been sitting together in the Air India lounge. One devotee had brought a battery operated phonograph player which, in those days was the latest invention. They put on an LP of Srila Prabhupada chanting and everybody sang (chanted) along. The entire lounge just floated away in an ocean of melodious chanting. It was very emotional.
                        Srila Prabhupada accompanied by Kirtanananda, whose head was shaven and who wore an incongruous black woolen suit, Prabhupada walked slowly toward the gate. As he disappeared from view, the devotees ran for the observation deck to get a last look at his departing plane.
                    </p>
                </div>
            </div>

            <div className="testimonial-item  border rounded  pt-5" >
                <img className="img-fluid bg-light rounded-circle p-2 mx-auto mb-1" src="../../img/t3.webp" style={{ width: 250, height: 250 }} />
                <div className="testimonial-text rounded text-center p-4">
                    <h3 className="mb-1">Mr. Narendra Modi</h3>
                    <h5 className="fst-italic mb-3">Prime Ministor of India</h5>

                    <p align="center" style={{ textAlign: "justify" }}>
                    The ISKCON family has emerged at the forefront of the quest to create a more peaceful, harmonious and compassionate society. ISKCON's journey has been a manifestation of 'Vasudhaiva Kutumbakam.' Integration has been at the core of your philosophy.

ISKCON's story has been the story of the determination and devotion of lakhs of devotees spread across the world. Inspired by the teachings of Lord Krishna, they spread His message far and wide. The efforts of the ISKCON family in sectors like education, publishing and disaster-relief are commendable. - Testimonials by Honourable Narendra Modi on Prabhupada's ISKCON Mission

Honourable Prime Minister of India Shri Narendra Modi Ji has kindly consented to officially inaugurate Srila Prabhupada’s 125th Appearance Anniversary celebrations, and also release the commemorative Prabhupada coin on September 1st, 2021 at 4:30 PM.    
                    </p>
                </div>
            </div>

            <div className="testimonial-item  border rounded  pt-5" >
                <img className="img-fluid bg-light rounded-circle p-2 mx-auto mb-1" src="../../img/t4.webp" style={{ width: 250, height: 250 }} />
                <div className="testimonial-text rounded text-center p-4">
                    <h3 className="mb-1">Mr. Lal Bahadur Shastri </h3>
                    <h5 className="fst-italic mb-3">Late Prime Minister</h5>

                    <p align="center" style={{ textAlign: "justify" }}>
                    Late Prime Minister Lal Bahadur Shastri died of a heart attack while visiting Russia. The Prime Minister had been a personal acquaintance of Srila Prabhupada's in India and an admirer of his Srimad-Bhagavatam translation. He had been scheduled to visit America, and Prabhupada had expected to obtain a personal sanction from him for the release of funds from India.

 His untimely death was a great upset in Srila Prabhupada's plans to purchase the building at 143 West Seventy-second Street. The realtors had shown him the building, and he had already mentally designed the interior for Deity worship and distribution of prasadam. The money was to come from India, and Prime Minister Shastri was to give personal sanction for release of the funds. But suddenly that was all changed
                    </p>
                </div>
            </div>

            <div className="testimonial-item  border rounded  pt-5" >
                <img className="img-fluid bg-light rounded-circle p-2 mx-auto mb-1" src="../../img/t5.webp" style={{ width: 250, height: 250 }} />
                <div className="testimonial-text rounded text-center p-4">
                    <h3 className="mb-1">George Harrison </h3>
                    <h5 className="fst-italic mb-3">Profession</h5>

                    <p align="center" style={{ textAlign: "justify" }}>
                    When Srila Prabhupada met George Harrison, he specially instructed and encouraged him to take Krishna consciousness more seriously and help teach it to the world. George donated a property he had just bought to Srila Prabhupada, to use for his mission in England. It was an iconic mock Tudor mansion in Hertfordshire, outside London. The devotees renovated the place and made it into a temple. It came to be called Bhaktivedanta Manor.

Srila Prabhupada had sent three married couples to preach in London. After a winter of struggle, they managed to meet George Harrison of the Beatles. George liked the devotees and began associating with them regularly. He liked the concepts of Krishna consciousness and began to express his devotion to Lord Krishna in his songs. George suggested that the devotees make a record on the Apple label.

The devotees went over to George's house for a chanting session. On the day of the recording, about a dozen devotees assembled at the E.M.I. With Paul McCartney and his wife Linda operating the console, George playing the organ and Mukunda playing the mridanga, Yamuna sang the lead with Shyamasundara backing her, the other voices blending in a chorus. The first day the record sold seventy thousand copies. Within a few weeks the devotees appeared on a popular TV show Top of the Pops, singing "their song."
                    </p>
                </div>
            </div>

            <div className="testimonial-item  border rounded  pt-5" >
                <img className="img-fluid bg-light rounded-circle p-2 mx-auto mb-1" src="../../img/t2.webp" style={{ width: 250, height: 250 }} />
                <div className="testimonial-text rounded text-center p-4">
                    <h3 className="mb-1">Devotees</h3>
                    <h5 className="fst-italic mb-3">Dummy Profession</h5>

                    <p align="center" style={{ textAlign: "justify" }}>
                    On February 22, 1976, three hundred fifty devotees arrived on a Boeing 747 Air-India jet at Dumdum Airport in Calcutta. The special flight, all devotees, had flown from Los Angeles to New York to London, picking up more devotees at each stop. As it was the first landing of a 747 in Calcutta, the mayor, local militia, leading Air-India dignitaries, and media people were on hand to greet the historic flight. One of the devotees told a reporter that the Boeing 747's coming from the West to Calcutta was the mercy of Lord Caitanya and Srila Prabhupada. Ten busloads of devotees then rode to Mayapur. This is the picture inside Boeing 747 with devotees doing kirtan on a flight.
                    </p>
                </div>
            </div>

            

        </Slider>
    );
}