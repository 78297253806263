import React, { useState, useEffect  } from 'react'
import Footer from '../../components/general/common/Footer'
import {NavMenu} from '../../components/general/common/NavMenu'
import { Link } from 'react-router-dom';
import callMyApi from '../../ApiCaller';

export default function Activities() {
  const [showSpinner, setShowSpinner] = useState(false);
    const [data, setData] = useState([]);
    const [groupedData, setGroupedData] = useState({});
    
    async function getData() {

        setShowSpinner(true);
        await callMyApi('admin/api/activities', 'GET')
            .then(res => {
                if (data) {
                    setData(res.record_per_page);
                    
                } else {
                    console.log('Unable to get slider data.')

                }
            }).catch(e => {

                console.log('Something went wrong, Please try again later.')

            });

    }
    useEffect(() => {
        getData();
    }, [])

    var grouped_data = data.reduce(function (r, a) {
      r[a.courseType] = r[a.courseType] || [];
      r[a.courseType].push(a);
      return r;
  }, Object.create(null));
  console.log(data)
  console.log(grouped_data);
  return (
    <div>
    <NavMenu/>
    
       {/* Page Header Start */}
       <div className="container-fluid page-header mb-5 wow fadeIn" data-wow-delay="0.1s">
      <div className="container text-center">
        <h1 className="display-4 text-white animated slideInDown mb-4">Our Activities</h1>
        <nav aria-label="breadcrumb animated slideInDown">
          <ol className="breadcrumb justify-content-center mb-0">
            <li className="breadcrumb-item"><Link className="text-white" to="/">Home</Link></li>
            {/* <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li> */}
            <li className="breadcrumb-item text-primary active" aria-current="page">Activities</li>
          </ol>
        </nav>
      </div>
    </div>
    {/* Page Header End */}
    
    
        {/* Service Start */}
        {data && data.length>0 ? <>
        <div className="container-xxl service py-3">
        {/* { Object.keys(data).map((key,i) => {  */}
      <div className="container" >
        <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
          {/* <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">Our Services</p> */}
          <h1 className="display-5 mb-5">Events</h1>
        </div>
    
    
      <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">
      <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-10">
        
      {data.map((item,index) => { 
        return<div className="rounded overflow-hidden shadow-lg">
          <a href="#" />
          <div className="relative">
            <a href="#">
              <img className="w-full" src={item.filePath} style={{maxHeight:"300px"}} alt="Sunset in the mountains" />
              <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25">
              </div>
            </a>
            <a href="#!">
              <div className="absolute bottom-0 right-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
              {item.date}
              </div>
            </a>
            <a href="!#">
              <div className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                <span className="font-bold">{item.time}</span>
                <small>IST</small>
              </div>
            </a>
          </div>
          <div className="px-6 py-2">
            <a href="#" className="font-semibold text-lg inline-block hover:text-indigo-600 transition duration-500 ease-in-out">{item.title}</a>
            {console.log(item.title)}
            <p className="text-gray-500 text-sm">
            {item.description}
            </p>
          </div>
          {/* <div className="px-6  flex flex-row items-center">
            <span href="#" className="py-1 text-sm font-regular text-gray-900 mr-1 flex flex-row items-center">
              <svg height="13px" width="13px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{enableBackground: 'new 0 0 512 512'}} xmlSpace="preserve">
                <g>
                  <g>
                    <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M277.333,256
          c0,11.797-9.536,21.333-21.333,21.333h-85.333c-11.797,0-21.333-9.536-21.333-21.333s9.536-21.333,21.333-21.333h64v-128
          c0-11.797,9.536-21.333,21.333-21.333s21.333,9.536,21.333,21.333V256z" />
                  </g>
                </g>
              </svg>
              <span className="ml-1">{item.time}</span></span>
          </div> */}
        </div>
        })}

      </div>
    </div>
    
    
        
        {/* <div className="row g-4 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-4">
            <div className="nav nav-pills d-flex justify-content-between w-100 h-100 me-4">
              <button className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4 active" data-bs-toggle="pill" data-bs-target="#tab-pane-1" type="button">
                <h5 className="m-0"><i className="fa fa-bars text-primary me-3" />Financial Planning</h5>
              </button>
              <button className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4" data-bs-toggle="pill" data-bs-target="#tab-pane-2" type="button">
                <h5 className="m-0"><i className="fa fa-bars text-primary me-3" />Cash Investment</h5>
              </button>
              <button className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4" data-bs-toggle="pill" data-bs-target="#tab-pane-3" type="button">
                <h5 className="m-0"><i className="fa fa-bars text-primary me-3" />Financial Consultancy</h5>
              </button>
              <button className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-0" data-bs-toggle="pill" data-bs-target="#tab-pane-4" type="button">
                <h5 className="m-0"><i className="fa fa-bars text-primary me-3" />Business Loans</h5>
              </button>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="tab-content w-100">
              <div className="tab-pane fade show active" id="tab-pane-1">
                <div className="row g-4">
                  <div className="col-md-6" style={{minHeight: 350}}>
                    <div className="position-relative h-100">
                      <img className="position-absolute rounded w-100 h-100" src="img/service-1.jpg" style={{objectFit: 'cover'}} alt />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h3 className="mb-4">25 Years Of Experience In Financial Support</h3>
                    <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu
                      diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit
                      clita duo justo erat amet.</p>
                    <p><i className="fa fa-check text-primary me-3" />Secured Loans</p>
                    <p><i className="fa fa-check text-primary me-3" />Credit Facilities</p>
                    <p><i className="fa fa-check text-primary me-3" />Cash Advanced</p>
                    <Link to={"#"} className="btn btn-primary py-3 px-5 mt-3">Read More</Link>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="tab-pane-2">
                <div className="row g-4">
                  <div className="col-md-6" style={{minHeight: 350}}>
                    <div className="position-relative h-100">
                      <img className="position-absolute rounded w-100 h-100" src="img/service-2.jpg" style={{objectFit: 'cover'}} alt />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h3 className="mb-4">25 Years Of Experience In Financial Support</h3>
                    <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu
                      diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit
                      clita duo justo erat amet.</p>
                    <p><i className="fa fa-check text-primary me-3" />Secured Loans</p>
                    <p><i className="fa fa-check text-primary me-3" />Credit Facilities</p>
                    <p><i className="fa fa-check text-primary me-3" />Cash Advanced</p>
                    <Link to={"#"} className="btn btn-primary py-3 px-5 mt-3">Read More</Link>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="tab-pane-3">
                <div className="row g-4">
                  <div className="col-md-6" style={{minHeight: 350}}>
                    <div className="position-relative h-100">
                      <img className="position-absolute rounded w-100 h-100" src="img/service-3.jpg" style={{objectFit: 'cover'}} alt />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h3 className="mb-4">25 Years Of Experience In Financial Support</h3>
                    <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu
                      diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit
                      clita duo justo erat amet.</p>
                    <p><i className="fa fa-check text-primary me-3" />Secured Loans</p>
                    <p><i className="fa fa-check text-primary me-3" />Credit Facilities</p>
                    <p><i className="fa fa-check text-primary me-3" />Cash Advanced</p>
                    <Link to={"#"} className="btn btn-primary py-3 px-5 mt-3">Read More</Link>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="tab-pane-4">
                <div className="row g-4">
                  <div className="col-md-6" style={{minHeight: 350}}>
                    <div className="position-relative h-100">
                      <img className="position-absolute rounded w-100 h-100" src="img/service-4.jpg" style={{objectFit: 'cover'}} alt />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h3 className="mb-4">25 Years Of Experience In Financial Support</h3>
                    <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu
                      diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit
                      clita duo justo erat amet.</p>
                    <p><i className="fa fa-check text-primary me-3" />Secured Loans</p>
                    <p><i className="fa fa-check text-primary me-3" />Credit Facilities</p>
                    <p><i className="fa fa-check text-primary me-3" />Cash Advanced</p>
                    <Link to={"#"} className="btn btn-primary py-3 px-5 mt-3">Read More</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* })} */}
    </div></>:
      <><img style={{maxHeight:"300px"}}className="mx-auto" src="../../img/no data.png" /><h3 className="text-center text-2xl text-primary pb-5">No Records Found</h3></>
      }
    {/* Service End */}
    
    <Footer/>
    
          </div>
  )
}
