
import Footer from '../../components/general/common/Footer'
import {NavMenu} from '../../components/general/common/NavMenu'
import React, { useState, useEffect } from 'react'
import callMyApi from '../../ApiCaller';
import { Link } from 'react-router-dom';


export default function Courses(){
  const [showSpinner, setShowSpinner] = useState(false);
    const [data, setData] = useState([]);
    const [groupedData, setGroupedData] = useState({});
    
    async function getData() {

        setShowSpinner(true);
        await callMyApi('admin/api/courses', 'GET')
            .then(res => {
                if (data) {
                    setData(res.record_per_page);
                    
                } else {
                    console.log('Unable to get slider data.')

                }
            }).catch(e => {

                console.log('Something went wrong, Please try again later.')

            });

    }
    useEffect(() => {
        getData();
    }, [])

    var grouped_data = data.reduce(function (r, a) {
      r[a.courseType] = r[a.courseType] || [];
      r[a.courseType].push(a);
      return r;
  }, Object.create(null));
  
  
  console.log(data)
console.log(grouped_data);

    return (
      <div>
<NavMenu/>

   {/* Page Header Start */}
   <div className="container-fluid page-header mb-5 wow fadeIn" data-wow-delay="0.1s">
  <div className="container text-center">
    <h1 className="display-4 text-white animated slideInDown mb-4">Vedic Courses</h1>
    <nav aria-label="breadcrumb animated slideInDown">
      <ol className="breadcrumb justify-content-center mb-0">
        <li className="breadcrumb-item"><Link className="text-white" to="#">Home</Link></li>
        {/* <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li> */}
        <li className="breadcrumb-item text-primary active" aria-current="page">Courses</li>
      </ol>
    </nav>
  </div>
</div>
{/* Page Header End */}


{/* Courses Start */}
{data && data.length>0 ? <>
<div className="container-xxl py-4">
  {/* short term start  */}

 { Object.keys(grouped_data).map((key,i) => { 
  return<div className="container mb-5"value={key}>
    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 500}}>
      <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3 display-6">{key}</div>
      {/* <h5 className="mb-5">Learn and grow in Krishna consciousness</h5> */}
    </div>
    <div className="row g-4 " >
    {console.log(data[key])}
    {grouped_data[key].map((item,index) => { 
      return<div className="col-lg-3 col-md-4 col-sm-6  wow fadeInUp" data-wow-delay="0.1s">
        <div className="causes-item d-flex flex-column bg-light border-top border-5 border-primary rounded-top overflow-hidden h-100">
          <div className="text-center p-3 pt-0">
            {/* <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-2">
              <small><a className='nav-link text-white' href="#">Essence of Bhagavad Gita</a></small>
            </div> */}
            <h5 className="mb-3 mt-4">{item.title}</h5>
            <p style={{textAlign:"justify"}}>{item.description}</p>
            {/* <div className="causes-progress bg-white p-3 pt-2">
              <div className="d-flex justify-content-between">
                <p className="text-dark">$10,000 <small className="text-body">Goal</small></p>
                <p className="text-dark">$9,542 <small className="text-body">Raised</small></p>
              </div>
              <div className="progress">
                <div className="progress-bar" role="progressbar" aria-valuenow={90} aria-valuemin={0} aria-valuemax={100}>
                  <span>90%</span>
                </div>
              </div>
            </div> */}
            <div className="d-flex justify-content-between mt-1">
                <p className="text-dark"><small className="text-primary">Duration </small> &nbsp;{item.duration}</p>
            </div>
            <div className="d-flex justify-content-between mt-1">
                <p className="text-dark"><small className="text-primary">Start from </small> &nbsp;{item.startDate} &nbsp;&nbsp; {item.startTime} IST</p>
            </div>
          </div>
          <div className="position-relative mt-auto" >
            <img className="img-fluid" src={item.filePath}   width="100%" />
            <div className="causes-overlay">
              {/* <Link className="btn btn-outline-primary" to="coursesregister?type=Short Term&&id=101&&name=Bhagavad Gita"> */}
              <Link className="btn btn-outline-primary" to={"/coursesregister?type="+item.courseType+"&&id="+item.id+"&&name="+item.title}>
             
                Register
                <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                  <i className="fa fa-arrow-right" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      
 })}
   
  


      

     

    </div>
  </div> 
  })}




</div></>:
      <><img style={{maxHeight:"300px"}}className="mx-auto" src="../../img/no data.png" /><h3 className="text-center text-2xl text-primary pb-5">No Records Found</h3></>
      }

{/* Courses End */}
<Footer/>

      </div>
    )
  }

