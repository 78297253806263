import React, { useEffect, Fragment, useRef,useState } from 'react'
import { json, useNavigate } from "react-router-dom"
import { Dialog, Transition } from '@headlessui/react'
import {TrophyIcon} from '@heroicons/react/24/outline'
import Calendar from 'react-calendar';
const ModalContent = ({openModal,setOpenModal,selectModalData,editModal,handleChangeSeletedDate,setSelectModalData,handleAction,newAddRecord}) => {
  return (
    <><Transition.Root show={openModal} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={(e)=>setOpenModal(!openModal)}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-3 pb-4 pt-2 sm:p-6 sm:pb-4">
                <div className="">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <TrophyIcon className="h-6 w-6 text-orange-500" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    {editModal ?  selectModalData.showMessage:selectModalData.showMessage}
                    </Dialog.Title>
                    <div className="mt-2">
                      {editModal ? ( selectModalData && <>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="col-span-full">
            <label htmlFor="first-name" className="flex flex-center block text-left text-sm font-medium leading-6 text-gray-900">
              Notification <abbr title='Please keep notification to 300 characters or less.'> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
</svg></abbr>
            </label>
            <div className="mt-2">
              <textarea
                name="notification"
                id="notification"
                rows={4}
                value={selectModalData.notification}
                onChange={(e)=>setSelectModalData({...selectModalData,notification:e.target.value})}
                autoComplete="given-name"
                className="block px-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          
         
          <div className="col-span-full">
            <label htmlFor="message" className="flex flex-center block text-left text-sm font-medium leading-6 text-gray-900">
              URL <abbr title='Please keep url to 500 characters or less.'> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
</svg></abbr>
            </label>
            <div className="mt-2">
              <textarea
                id="url"
                name="url"
                rows={3}
                value={selectModalData.url}
                onChange={(e)=>{setSelectModalData({...selectModalData,url:e.target.value})}}
                className="block px-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6"
                placeholder='Enter the URL'
              />
            </div>
          </div>
          {/* <div className="col-span-full">
            <label htmlFor="about" className="block text-left text-sm font-medium leading-6 text-gray-900">
              Date & Time
            </label>
            <div className="mt-2">
            <Calendar onChange={handleChangeSeletedDate} value={new Date(selectModalData.datetime)} />
            </div>
            <p className="mt-3 text-sm leading-6 text-gray-600">Choose the date for current data</p>
          </div> */}
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="mt-3 items-center  inline-flex w-full justify-center rounded-md bg-orange-400 px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                  onClick={(e) => handleAction(e,selectModalData,`${newAddRecord ? "add_record":"update"}`)}
                >
                  {newAddRecord ? "Submit" :"Update"}
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={(e) => handleAction(e,selectModalData,'close')}
                >
                  Cancel
                </button>
              </div>
                      </>):(
                          <>
                          <div className="col-span-full">
            <label htmlFor="about" className="mt-2 mb-3 block text-left text-sm font-medium leading-6 text-gray-900">
            Are you sure want to delete this data ?
            </label>
            
            </div>
            <div className="col-span-full">
            <label htmlFor="about" className="mt-2  block text-left text-sm font-medium leading-6 text-gray-900">
            Notification : {selectModalData.notification}
            </label>
            
            </div>
            <div className="col-span-full">
            <label htmlFor="about" className="mt-2 block text-left text-sm font-medium leading-6 text-gray-900">
            URL : {selectModalData.url}
            </label>
            
            </div>
            
            <div className="col-span-full">
            <label htmlFor="about" className="mt-2 block text-left text-sm font-medium leading-6 text-gray-900">
            Date Time : {selectModalData.datetime}
            </label>
            
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="mt-3 items-center inline-flex w-full justify-center rounded-md bg-orange-400 px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                  onClick={(e) => handleAction(e,selectModalData,"delete")}
                >
                  Yes,Delete
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={(e) => handleAction(e,selectModalData,"close")}
                >
                  No,Cancel
                </button>
              </div>
                          
                          </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root></>
  )
}

export default ModalContent;