async function callMyApi(url, data, apiMethod = 'GET', headers = null) {

    const message = "Sorry, the request was unsuccessfull. Please come back later.";
    //var base_url = process.env.REACT_APP_API_END_POINT ;
    var login_info = JSON.parse(localStorage.getItem('login-info'));
    if (headers == null) {
        headers = {
            "Accept": "*/*",
            "Content-Type": "application/json",
           // "Content-Type": data.file?"multipart/form-data":"application/json"
        }
    }
    if (data.file) {
        headers = {
            "Accept": "*/*",
        }
    }
    if (login_info && login_info !== null && login_info !== undefined) {
        var token=login_info.token;
        if (token && token !== null && token !== undefined){
        headers.Authorization = "Bearer " + token
        }
    }
     if (apiMethod == 'POST' || apiMethod == 'PUT') {
        delete headers['Content-Type'];
        //convertion json object to formData
        console.log( data.file?data:JSON.stringify(data))
        var form_data = new FormData();
        for ( var key in data ) {
            console.log('file')
            form_data.append(key, data[key]);
        }

        return fetch(url, {
            method: apiMethod,
            headers: headers,
           // body: JSON.stringify(data)
           body:  form_data
        }).then(res => {
            var jres = res.json();
            return jres;
        }).catch(e => {
                console.log(e);
                throw e
            });
    }
    // else if (apiMethod == 'PUT') {
    //     return fetch(url, {
    //         method: apiMethod,
    //         headers: headers,
    //         body: JSON.stringify(data)
    //     }).then(res => {
    //         var jres = res.json();
    //         return jres;
    //     }).catch(e => {
    //             console.log(e);
    //             throw e
    //         });
    // }
    else if (apiMethod == 'DELETE') {
        console.log(apiMethod)
        return fetch(url, {
            method: apiMethod,
            headers: headers,
            body: JSON.stringify(data)
        }).then(res => {
            var jres = res.json();
            return jres;
        }).catch(e => {
                console.log(e);
                throw e
            });
    }
    else if (apiMethod == 'GET') {
        return fetch(url, {
            method: apiMethod,
            headers: headers,
            //body:data
        }).then(res => {
            var jres = res.json()
            return jres;
        })
            .catch(e => {
                return {
                    'success': false,
                    'message': message,
                    'ecode': e
                }
            });
    } else {
        return {
            'success': false,
            'message': 'Method not allowed.'
        }
    }
}

export default callMyApi;