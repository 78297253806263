import React ,{ Fragment,useState} from 'react';
// import { PRODUCT_PER_PAGE } from '../../app/contants';
import {
    ChevronLeftIcon,
    ChevronRightIcon,
  } from "@heroicons/react/20/solid";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
const pageCountOption =[
    {label:10,value:10,selected:true},
    {label:25,value:25,selected:false},
    {label:50,value:50,selected:false},
    {label:100,value:100,selected:false},
]
const Pagination = ({page,setPage,handlePage,totalItem,pageCount,setPageCount}) => {
    const [selected, setSelected] = useState(pageCountOption[0])
    const totalPage =Math.ceil(totalItem/pageCount);  
    const handleChangePageCount =(e)=>{
        var selectPageCount =pageCountOption.find((pagecount)=>{
            return pagecount.value === e;
        })
        setPageCount(e);
        setSelected(selectPageCount)
    } 
  return (
    <div className="mb-12 flex items-center justify-between border-t border-gray-200 bg-white px-2 py-2 sm:px-2">
            <div className="flex flex-1 justify-between sm:hidden">
              <div  onClick={e=>handlePage(page >1 ?page-1 : page)}
                className="relative cursor-pointer inline-flex  items-center rounded-md border border-orange-500 bg-white px-4 py-2 text-sm font-medium text-orange-500 hover:bg-orange-50"
              >
                Previous
              </div>
              <div onClick={e=>handlePage(page < totalPage ?page+1 : page)}
                className="relative cursor-pointer ml-3 inline-flex items-center rounded-md border border-orange-500 bg-white px-4 py-2 text-sm font-medium text-orange-500 hover:bg-orange-50"
              >
                Next
              </div>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-orange-500">
                  Showing <span className="font-medium">{(page-1)*pageCount+1}</span> to{" "}
                  <span className="font-medium">{page*pageCount> totalItem ? totalItem :page*pageCount }</span> of{" "}
                  <span className="font-medium">{totalItem}</span> results
                </p>
              </div>
              {/*Start Change Page count Options  */}
              <div className='flex items-center mx-2 justi'>
              <Listbox value={selected} onChange={(e)=>handleChangePageCount(e)}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-orange-500 mt-2 mr-3">Selected Page Count</Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-6 pr-12 text-left text-orange-500 shadow-sm ring-1 ring-inset ring-orange-400  focus:outline-none focus:ring-2 focus:ring-orange-500 sm:text-sm sm:leading-6">
              <span className="flex items-center">
                <span className="ml-3 block truncate">{selected.label}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-orange-500" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="bottom-10 absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {pageCountOption.map((person,index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-orange-500 text-white' : 'text-orange-500',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={person.value}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                          >
                            {person.label}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-orange-500',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
              </div>
              {/* End Chnage page count options */}
              <div>
                {/* Start Page Number chnage work */}
                <nav
                  className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <div onClick={e=>handlePage(page >1 ?page-1 : page)}
                    className="relative cursor-pointer inline-flex items-center rounded-l-md px-2 py-2 text-orange-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5 text-ornage-500 hover:bg-orange-600 hover:text-white" aria-hidden="true" />
                  </div>
                  {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
                  
                  {Array.from({length:totalPage}).map((el,index)=>(
                    <div
                    key={index}
                    onClick={e=>handlePage(index+1)}
                    aria-current="page"
                    className={`relative cursor-pointer z-10 inline-flex items-center ${index+1 === page ? "bg-orange-500 text-white"  :" text-orange-500 border  border-gray-300 hover:bg-orange-500 hover:text-white" } px-3 py-2 text-sm font-semibold  focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500`}
                  >
                    { index+1}
                  </div>
                  ))
                
                
                
                }
                  
                  
                  <div onClick={e=>handlePage(page < totalPage ?page+1 : page)}
                    className="relative cursor-pointer inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5 text-orange-500 hover:bg-orange-500 hover:text-white" aria-hidden="true" />
                  </div>
                </nav>
                {/* End Page Number change work */}
              </div>
            </div>
          </div>
  )
}

export default Pagination;