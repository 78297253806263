import React from 'react'

export default function Mission() {
  return (
    <div>
        {/* Mission Start */}
<div className="container-xxl py-5">
 <div className="container">
     <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ }}>
         <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3 display-5">Our Mission</div>
         <p className="bg-light  border-2 border-primary rounded p-4 mb-4" style={{ border:" dashed"}}>Our community is committed to impart spiritual education as per our Vedic scriptures in an easy and understandable way and promote unity, peace and harmony in the world.
We do this through the congregational chanting of the holy name, distributing sanctified food, propagating knowledge of the great ancient vedic scriptures as presented by His Divine Grace A.C. Bhakti Vedanta Swami Srila Prabhupada.</p>
     </div>
 
 </div>
</div>
{/* Mission End */}
    </div>
  )
}
