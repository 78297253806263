import React from 'react'

function Alert(props) {
    function hide() {
        if (props.showAlart == true) {
            document.getElementById("box").style.display = "block";
            setTimeout(() => {
                document.getElementById("box").style.display = "none";
                props.updateShowAlert(false);
            }, 5000);
        }

    }
    return (
        <>
            {hide()}
            {/* {props.showAlart == true ? console.log(props.showAlart, " from alert") : ""} */}
            {/* alart section */}
            
            <div className="col-12" id="box" style={{ display: "block" }}>
                {
                    props.showAlart == true ? <div className={'alert alert-' + props.type + ' alert-dismissible fade show wow fadeOutUp'} data-wow-delay="4s" role="alert">
                        <strong>{props.type=='success'? 'Info':'Error'} -  {typeof (props.userMessage) == 'object' ? props.userMessage.map((err, index) => (
                            <li key={index}>
                                {/* {index+1}. {err} */}
                                {err}
                                {/* <span>{props.userMessage.length-1 ==index?"":","} </span> */}
                            </li>
                        )) : props.userMessage}  </strong>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
                    </div> : ""
                }
                {/* {setShowAlert(false)} */}
            </div>
        </>
    )
}

export default Alert