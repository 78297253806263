import React, { useState, useEffect } from 'react'
import callMyApi from '../../ApiCaller';
import { Link } from 'react-router-dom';

export default function Notification() {
    const [showSpinner, setShowSpinner] = useState(false);
    const [data, setData] = useState([]);
    async function getData() {

        setShowSpinner(true);
        await callMyApi('admin/api/slider', 'GET')
            .then(res => {
                if (data) {
                    setData(res.record_per_page);
                } else {
                    console.log('Unable to get slider data.')

                }
            }).catch(e => {

                console.log('Something went wrong, Please try again later.')

            });

    }
    useEffect(() => {
        getData();
    }, [])
    
    return (
        <div>
            {/* Carousel Start */}
            {data && data.length > 0 ?
                <div className="container-fluid p-0">
                     <div id="header-carousel" className="carousel slide" data-bs-ride="carousel" >
                        <div className="carousel-inner">
                            {data.map((item,index) => {
                              return  <div className={index==1?"carousel-item active":"carousel-item"}>
                                    <img className="w-100" src={item.filePath} style={{maxHeight:"750px"}}  alt="Image" />
                                    <div className="carousel-caption">
                                        <div className="container">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-7 pt-5">
                                                    <h1 className="display-6 text-white mb-3 animated slideInDown"> {item.heading1?item.heading1:""}</h1>
                                                    <p className="fs-5 text-white-50 mb-5 animated slideInDown">{item.heading2?item.heading2:""}</p>
                                                  {item.actionUrl && item.actionUrl!='#' ?<Link className="btn btn-primary py-2 px-3 animated slideInDown" to={item.actionUrl}>
                                                        Learn More
                                                        <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                                            <i className="fa fa-arrow-right" />
                                                        </div>
                                                    </Link>:""}  
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                            {/* <div className="carousel-item">
                                <img className="w-100" src="img/slider/slide4.jpeg" alt="Image" />
                                <div className="carousel-caption">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-7 pt-5">
                                            <h1 className="display-6 text-white mb-3 animated slideInDown">A.C. Bhaktivedanta Swami Prabhupada (1896-1977)</h1>
                                                <p className="fs-5 text-white-50 mb-5 animated slideInDown">He is the Founder-Acharya of the International Society for Krishna Consciousness (ISKCON) and the world’s most respected spiritual leader of this age. He came to New York in 1965 and in twelve short years made the chanting of 'Hare Krishna' known throughout the world.</p>
                                                
                                                <Link className="btn btn-primary py-2 px-3 animated slideInDown" to="#">
                                                    Learn More
                    <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                                        <i className="fa fa-arrow-right" />
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item">
                                <img className="w-100" src="img/slider/slide1.jpeg" alt="Image" />
                                <div className="carousel-caption">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-7 pt-5">
                                                <h1 className="display-6 text-white mb-3 animated slideInDown">Let's Save More Lifes With Our Helping Hand</h1>
                                                <p className="fs-5 text-white-50 mb-5 animated slideInDown">Aliqu diam amet diam et eos. Clita erat ipsum et lorem sed stet lorem sit clita duo justo erat amet</p>
                                                <Link className="btn btn-primary py-2 px-3 animated slideInDown" to="#">
                                                    Learn More
                    <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                                        <i className="fa fa-arrow-right" />
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item">
                                <img className="w-100" src="img/slider/slide2.jpeg" alt="Image" />
                                <div className="carousel-caption">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-7 pt-5">
                                                <h1 className="display-6 text-white mb-3 animated slideInDown">Let's Save More Lifes With Our Helping Hand</h1>
                                                <p className="fs-5 text-white-50 mb-5 animated slideInDown">Aliqu diam amet diam et eos. Clita erat ipsum et lorem sed stet lorem sit clita duo justo erat amet</p>
                                                <Link className="btn btn-primary py-2 px-3 animated slideInDown" to="#">
                                                    Learn More
                    <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                                        <i className="fa fa-arrow-right" />
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true" />
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true" />
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div> : <></>}
            {/* Carousel End */}
        </div>
    )
}
