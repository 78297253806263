import React from 'react'

export default function Footer() {
  return (
    <div>
         {/* Footer Start */}
         <div className="container-fluid bg-dark text-white-50 footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
                    <div className="container py-5">
                        <div className="row g-5">
                            <div className="col-lg-3 col-md-6">
                                <h2 className="fw-bold text-primary mb-4">Iskcon<span className="text-white">Chitrakoot</span></h2>
                                <p>Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita</p>
                                <div className="d-flex pt-2">
                                <a className="btn btn-square me-1" href="https://www.facebook.com/iskconchkt/"><i className="fab fa-facebook-f" /></a>
                                <a className="btn btn-square me-1" href="https://twitter.com/iskconchitrakut?lang=en-GB"><i className="fab fa-twitter" /></a>
                                <a className="btn btn-square me-1" href="https://www.linkedin.com/company/iskcon-chitrakoot-up/"><i className="fab fa-linkedin-in" /></a>
                                <a className="btn btn-square me-1" href="https://www.instagram.com/iskcon_chitrakoot_official/"><i className="fab fa-instagram" /></a>
                                <a className="btn btn-square me-1" href="https://www.youtube.com/@iskconchitrakoot9323"><i className="fab fa-youtube" /></a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <h5 className="text-light mb-4">Address</h5>
                                <p><i className="fa fa-map-marker-alt me-3" />ISKCON Chitrakoot, Chitra Road, Near water tank, Sitapur, Chitrakoot, 210204, India</p>
                                <p><i className="fa fa-phone-alt me-3" />+91 70123 63367</p>
                                <p><i className="fa fa-envelope me-3" />info.iskconcktd@gmail.com</p>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <h5 className="text-light mb-4">Quick Links</h5>
                                <a className="btn btn-link" href="#">About Us</a>
                                <a className="btn btn-link" href="#">Contact Us</a>
                                <a className="btn btn-link" href="#">Our Services</a>
                                <a className="btn btn-link" href="#">Terms &amp; Condition</a>
                                <a className="btn btn-link" href="#">Support</a>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <h5 className="text-light mb-4">Newsletter</h5>
                                <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
                                <div className="position-relative mx-auto" style={{ maxWidth: 400 }}>
                                    <input className="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
                                    <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid copyright">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                    © <a href="#">Iskcon CHitrakoot</a>, All Right Reserved.
          </div>
                                <div className="col-md-6 text-center text-md-end">
                                 Developed By Devotees {/* <a href="https://mykss.in">KSS Chitrakoot</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer End */}
               
    </div>
  )
}
