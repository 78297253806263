import React, { useEffect, Fragment, useRef, useState } from 'react'
import Layout from '../../components/admin/Layout'
import Footer from '../../components/admin/Footer'
import Alert from '../../components/general/common/Alert';
import callMyApi from '../../ApiCaller';
import Pagination from '../../components/admin/Pagination';
import EditActivities from '../../components/admin/EditActivities'
import { Link } from 'react-router-dom';
import Loader from '../../components/general/common/Loader';

export default function Activities() {
  const [response, setResponse] = useState("");
  // const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState("");
  const [alertMessageType, setAlertMessageType] = useState("");
  const [showAlart, setShowAlert] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selectModalData, setSelectModalData] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date);
  const [searchWords, setSearchWords] = useState('');
  const [checkedData, setCheckData] = useState([]);
  const [newAddRecord ,setNewAddRecord]=useState(false);
  const handleEditData = (e, item, index) => {
    console.log(item);
    console.log(index);
    setSelectModalData({ ...item, showMessage: "Update Record", rowIndex: index });
    setEditModal(true);
    setOpenModal(!openModal);

  }

  const handleAddData = (e, item, index) => {
    console.log(item);
    console.log(index);
    setNewAddRecord(true);
    setSelectModalData({showMessage: "Add Activities", rowIndex: index });
    setEditModal(true);
    setOpenModal(!openModal);

  }

  const handleDeleteData = (e, item, index) => {
    setEditModal(false);
    setNewAddRecord(false);
    setSelectModalData({ ...item, showMessage: "Delete Own Record", rowIndex: index });
    setOpenModal(!openModal);
  }
  const handlePage = (pageOption) => {
    setPage(pageOption);
  }
  const handleAction = (e, selectedModalData, action) => {
    if (action === 'close') {
      setOpenModal(false)
    }
    else if (action === "update") {
        setNewAddRecord(false);
      asynEditData(selectedModalData);
      setOpenModal(false)
    }
    else if (action === 'delete') {
        setNewAddRecord(false);
      singleDeleteData(selectedModalData);
      setOpenModal(false);
    }
    else if(action === 'add_record'){
        setShowSpinner(true);
        asyncAddNewdata(selectedModalData);
        setOpenModal(false);
        setNewAddRecord(false);
    }
  }
  async function asyncAddNewdata(selectedModalData) {
    
    if ('showMessage' in selectedModalData) {
        delete selectedModalData.showMessage
      }
      if ('rowIndex' in selectedModalData) {
        delete selectedModalData.rowIndex
      }
      console.log(selectModalData)
      await callMyApi(`admin/api/Activities`, selectedModalData, 'POST')
      .then(res => {
        if (res.status) {
          setAlertMessageType("success");
          setShowAlert(true);
          setNewAddRecord(false);
          setAlertMessage(res.userMessage);
          getData();
        }
        else {
          setAlertMessageType("danger");
          setShowAlert(true);
          setAlertMessage(res.userMessage);
        }
      }).catch(e => {
        setShowAlert(true);
        setAlertMessageType("danger");
        setAlertMessage("Something went wrong, unable to create record.");
      })
    setShowSpinner(false);
  }

  async function asynEditData(selectedModalData) {
    setShowSpinner(true);
    if ('showMessage' in selectedModalData) {
      delete selectedModalData.showMessage
    }
    if ('rowIndex' in selectedModalData) {
      delete selectedModalData.rowIndex
    }
    await callMyApi(`admin/api/Activities/${selectedModalData.id}`, selectedModalData, 'PUT')
      .then(res => {
        if (res.status) {
          setAlertMessageType("success");
          setShowAlert(true);
          setNewAddRecord(false);
          setAlertMessage(res.userMessage);
          getData();
        }
        else {
          setAlertMessageType("danger");
          setShowAlert(true);
          setAlertMessage("No record are updated");
        }
      }).catch(e => {
        setShowAlert(true);
        setAlertMessageType("danger");
        setAlertMessage(e.userMessage);
      })
    setShowSpinner(false);
  }
  async function singleDeleteData(selectedModalData) {
    setShowSpinner(true);
    let preapareData = {
      deleteData: [selectedModalData.id]
    }
    await callMyApi(`admin/api/Activities`, preapareData, 'DELETE')
      .then(res => {
        if (res.status) {
          setAlertMessageType("success");
          setShowAlert(true);
          setNewAddRecord(false);
          setAlertMessage(res.userMessage);
          getData();
        }
        else {
          setShowAlert(true);
          setAlertMessageType("danger")
          setAlertMessage("No record are deleted");
        }
      }).catch(e => {
        setAlertMessageType("danger");
        setShowAlert(true);
        setAlertMessage(e.userMessage);
      })
    setShowSpinner(false)
  }
  const handleSerchFilter = (e) => {
    setSearchWords(e.target.value);
  }
  const handleChangeSeletedDate = (e) => {
  }
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setCheckData(data);
    }
    else {
      setCheckData([]);
    }
  }
  const handleCheckSelectedData = (e, item) => {
    let updateCheckListData = [...checkedData];
    if (e.target.checked) {
      updateCheckListData = [...checkedData, item];
    }
    else {
      let listItem = checkedData.findIndex((list) => list.id === item.id);
      updateCheckListData.splice(listItem, 1)
    }
    setCheckData(updateCheckListData);
  }

  const updateShowAlert = (props) => {
    setShowAlert(false)
  }
  const handleBulkDeleteData = (e) => {
    if(checkedData.length>0){
      buldDataDelete();
    }
    else{
      setAlertMessageType("danger");
          setShowAlert(true);
          setAlertMessage("Please selete the record first");
    }
    

  }
  useEffect(() => {
    getData();
  }, [page, pageCount, searchWords]);
  useEffect(() => {

  }, [checkedData])

  async function buldDataDelete() {
    setShowSpinner(true);
    let idArray = checkedData.map((seletedData) => {
      return seletedData.id
    });
    let preapareData = {
      deleteData: idArray
    }
    await callMyApi(`admin/api/Activities`, preapareData, 'DELETE')
      .then(res => {
        if (res.status) {
          setAlertMessageType("success");
          setShowAlert(true);
          getData();
          setNewAddRecord(false);
          setAlertMessage(res.userMessage);
        }
        else {
          setAlertMessageType("danger")
          setShowAlert(true);
          setAlertMessage("No record are deleted");
        }
      }).catch(e => {
        setAlertMessageType("danger")
        setShowAlert(true);
        setAlertMessage(e.userMessage);
      })
    setShowSpinner(false)
  }

  async function getData() {

    setShowSpinner(true);
    await callMyApi(searchWords && searchWords !== '' ? `admin/api/Activities?page=${page}&page_size=${pageCount}&search=${searchWords}` : `admin/api/Activities?page=${page}&page_size=${pageCount}`, 'GET')
      .then(res => {
        if (data) {
          setData(res.record_per_page);
          setTotalItem(res.total_records);
        } else {
            setData([]);
          setShowAlert(true);
          setAlertMessageType('danger');
          setAlertMessage(res.userMessage);
        }
      }).catch(e => {
        setData([]);
        setShowAlert(true);
        setAlertMessageType('danger');
        setAlertMessage('Something went wrong, Please try again later.')

      });
    setShowSpinner(false)
  }
  return (
    <div className='container-fluid'>
      {showSpinner ? <Loader /> : ""}
      <Layout />
      <div className='row' style={{ paddingTop: "5em" }}>
        <div className='col-sm-1'></div>
        <div className='col-sm-10 pb-4'>
          <div className='flex items-center justify-between my-3 col-span-2'>
            <h1 className='fs-1 mb-1'>Activities</h1><hr />
            
            {/* Start add  Work */}
            <div className='flex items-center justify-end col-span-12'>
              <div className='col-span-2'>
                <button
                  onClick={(e) => handleAddData(e)}
                  className="py-2 px-3 rounded-lg bg-orange-500 mr-2 text-white dark:text-white hover:bg-orange-600 inline-flex items-center"
                >
                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>&nbsp;Add
                </button>
              </div>
              {/* End add work */}

            {/* Start Bulk Delete  Work */}
              <div className='col-span-2'>
                <button
                  onClick={(e) => handleBulkDeleteData(e)}
                  className="py-2 px-3 rounded-lg bg-orange-500 mr-2 text-white dark:text-white hover:bg-orange-600"
                >
                  Bulk Delete
                </button>
              </div>
              {/* End Bulk Delete work */}
              {/* Start Search box  */}
              <div className="w-96 overflow-hidden rounded-lg bg-orange-50 text-white shadow-md" id="headlessui-dialog-panel-6" >
                <div className="relative flex items-center justify-center">
                  <input className="block w-full appearance-none bg-transparent py-2 pl-4 pr-12 text-base text-slate-900 placeholder:text-slate-600 focus:outline-none sm:text-sm sm:leading-6" placeholder="Find by title or description" aria-label="Search components" role="combobox" type="text" aria-expanded="false" aria-autocomplete="list" style={{ caretColor: 'rgb(107, 114, 128)' }} value={searchWords} onChange={(e) => handleSerchFilter(e)} />
                  <svg className="pointer-events-none absolute right-4 h-6 w-6 fill-slate-400" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.47 21.53a.75.75 0 1 0 1.06-1.06l-1.06 1.06Zm-9.97-4.28a6.75 6.75 0 0 1-6.75-6.75h-1.5a8.25 8.25 0 0 0 8.25 8.25v-1.5ZM3.75 10.5a6.75 6.75 0 0 1 6.75-6.75v-1.5a8.25 8.25 0 0 0-8.25 8.25h1.5Zm6.75-6.75a6.75 6.75 0 0 1 6.75 6.75h1.5a8.25 8.25 0 0 0-8.25-8.25v1.5Zm11.03 16.72-5.196-5.197-1.061 1.06 5.197 5.197 1.06-1.06Zm-4.28-9.97c0 1.864-.755 3.55-1.977 4.773l1.06 1.06A8.226 8.226 0 0 0 18.75 10.5h-1.5Zm-1.977 4.773A6.727 6.727 0 0 1 10.5 17.25v1.5a8.226 8.226 0 0 0 5.834-2.416l-1.061-1.061Z" />
                  </svg>
                </div>
              </div>
              {/* End Search Box  */}
            </div>
          </div>
          <>
            {/* Start data Table Work*/}
            <div className="w-full mx-auto">
              <div className='my-2 w-full'>
                {/* {    <Alert showAlart={showAlart} type="danger" userMessage={response.userMessage} updateShowAleart={updateShowAlert} />} */}
                {<Alert showAlart={showAlart} type={alertMessageType} userMessage={alertMessage} updateShowAlert={updateShowAlert} />}
              </div>
              <div className="flex flex-col">
                <div className="overflow-x-auto shadow-md sm:rounded-lg">
                  <div className="inline-block min-w-full align-middle">
                    <div className="overflow-hidden ">
                      <table className="min-w-full divide-y divide-gray-200 table-fixed bg-white">
                        <thead className="bg-orange-50 dark:bg-orange-50 text-gray-700 ">
                          <tr>
                            <th scope="col" className="p-2">
                              <div className="flex items-center">
                                <div className="inline-flex items-center">
                                  <label
                                    className="relative flex cursor-pointer items-center rounded-full p-3"
                                    htmlFor="checkbox-1"
                                    data-ripple-dark="true"
                                  >
                                    <input
                                      id="checkbox-all"
                                      type="checkbox"
                                      onChange={e => handleSelectAll(e)}
                                      checked={checkedData.length === data.length}
                                      className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-md border border-orange-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-orange-gray-500 before:opacity-0 before:transition-opacity checked:border-orange-500 checked:bg-orange-500 checked:text-white checked:before:bg-orange-500 hover:before:opacity-10"
                                    />
                                    <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-3.5 w-3.5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        stroke="currentColor"
                                        strokeWidth="1"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                          clipRule="evenodd"
                                        ></path>
                                      </svg>
                                    </div>

                                  </label>
                                </div>
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-1 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-800"
                            >
                              Id
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-800"
                            >
                              Banner
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-800"
                            >
                              Title
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-800"
                            >
                              Description
                            </th>
                          
                            
                            <th
                              scope="col"
                              className="py-3 px-4 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-800"
                            >
                              Date
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-4 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-800"
                            >
                              Time
                            </th>
                           
                            
                            <th
                              scope="col"
                              className="py-3 px-4 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-800"
                            >
                              Date & Time
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-4 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-800"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                          {data && data.length> 0 ? data.map((item, index) => {
                            return <tr key={index} className="bg-white-100 even:bg-orange-50">
                              <td className="p-2 w-4">
                                <div className="flex items-center">
                                <div className="inline-flex items-center">
                                  <label
                                    className="relative flex cursor-pointer items-center rounded-full p-3"
                                    htmlFor="checkbox-1"
                                    data-ripple-dark="true"
                                  >
                                  <input
                                    id={`checkbox-table-${index}`}
                                    type="checkbox"
                                    onChange={e => handleCheckSelectedData(e, item)}
                                    checked={checkedData.includes(item) || checkedData.length === data.length}
                                    className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-md border border-orange-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-orange-gray-500 before:opacity-0 before:transition-opacity checked:border-orange-500 checked:bg-orange-500 checked:text-white checked:before:bg-orange-500 hover:before:opacity-10"
                                  />
                                  <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-3.5 w-3.5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        stroke="currentColor"
                                        strokeWidth="1"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                          clipRule="evenodd"
                                        ></path>
                                      </svg>
                                    </div>
                                  </label>
                                  </div>
                                </div>
                              </td>
                              <td className="py-4 px-1 text-sm font-medium text-gray-900 whitespace-nowrap ">
                                {index + 1}
                              </td>
                              <td className="py-4 px-2 text-sm font-medium text-gray-900 whitespace-nowrap ">
                              <a href={item.filePath}>
                              <img src={item.filePath} alt={'slide_image'+ (index+1)} height="65px" width="110px"/>
                                </a>
           
                              </td>
                              <td style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}} className="py-4 px-2 text-sm font-medium text-gray-900 whitespace-nowrap ">
                                {item.title}
                              </td>
                              <td style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}} className="py-4 px-2 text-sm font-medium text-gray-900 whitespace-nowrap ">
                                {item.description}
                              </td>
                              <td style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}} className="py-4 px-2 text-sm font-medium text-gray-900 whitespace-nowrap ">
                                {item.date}
                              </td>
                              <td style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}} className="py-4 px-2 text-sm font-medium text-gray-900 whitespace-nowrap ">
                                {item.time} IST
                              </td>
                             
                             
                              <td className="py-4 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                {item.datetime} IST
                              </td>
                              <td className="py-4 px-2 text-sm font-medium text-right whitespace-nowrap">
                                <button
                                  onClick={(e) => handleEditData(e, item, index)}
                                  className="mr-2 text-orange-500 dark:text-orange-500 hover:underline"
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={(e) => handleDeleteData(e, item, index)}
                                  className="text-orange-500 dark:text-orange-500 hover:underline mx-2"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          }):<tr className="bg-white-100 even:bg-orange-50 w-full"><td className='p-2'> No Records found</td></tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End data table work */}
          </>
 {/* Start Pagionation Work */}
 <div className='mb-4'>
          <Pagination
            page={page}
            setPage={setPage}
            handlePage={handlePage}
            totalItem={totalItem}
            pageCount={pageCount}
            setPageCount={setPageCount}
            setSelectModalData={setSelectModalData}
          />
        </div>
        {/* End pagination Work  */}
        </div>
        <div className='col-sm-1'></div>

       
      </div>

      <Footer />
      {/* Start Modal work for action */}
      <EditActivities
        editModal={editModal}
        openModal={openModal}
        selectModalData={selectModalData}
        setOpenModal={setOpenModal}
        setSelectModalData={setSelectModalData}
        handleChangeSeletedDate={handleChangeSeletedDate}
        handleAction={handleAction}
        newAddRecord={newAddRecord}
      />
      {/* End Modal Work for action */}
    </div>
  )
}
