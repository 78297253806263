import React, { useEffect} from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
//import './NavMenu.css';

export function NavMenu(){
    const scrollToView = id_string => { 
        document.querySelector(`#${id_string}`).scrollIntoView()
    }
    return (
        
            <div>
                {/* Navbar Start */}
                <div className="container-fluid fixed-top px-0 wow fadeIn" data-wow-delay="0.1s">
                    <div className="top-bar text-white-50 row gx-0 align-items-center d-none d-lg-flex">
                        <div className="col-lg-8 px-5 text-start">
                            <small><i className="fa fa-map-marker-alt me-2" />ISKCON, Chitra Rd, near water tank, Sitapur, Chitrakoot, 210204, India</small>
                            <small className="ms-4"><i className="fa fa-envelope me-2" />info.iskconcktd@gmail.com</small>
                        </div>
                        <div className="col-lg-4 px-5 text-end">
                            <small>Follow us:</small>
                            <a className="text-white-50 ms-3" href="https://www.facebook.com/iskconchkt/"><i className="fab fa-facebook-f" /></a>
                            <a className="text-white-50 ms-3" href="https://twitter.com/iskconchitrakut?lang=en-GB"><i className="fab fa-twitter" /></a>
                            <a className="text-white-50 ms-3" href="https://www.linkedin.com/company/iskcon-chitrakoot-up/"><i className="fab fa-linkedin-in" /></a>
                            <a className="text-white-50 ms-3" href="https://www.instagram.com/iskcon_chitrakoot_official/"><i className="fab fa-instagram" /></a>
                            <a className="text-white-50 ms-3" href="https://www.youtube.com/@iskconchitrakoot9323"><i className="fab fa-youtube" /></a>
                        </div>
                    </div>
                    <nav className="navbar navbar-expand-lg navbar-dark py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
                        <Link to="/" className="navbar-brand ms-4 ms-lg-0">
                        <h1 className="fw-bold text-primary m-0">{/*<img src="img/iskconLogo.webp" style={{maxHeight:"50px",borderRadius:"10px"}} />*/} Iskcon<span className="text-white">Chitrakoot</span></h1>
                        </Link>
                        <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                            <span className="navbar-toggler-icon" />
                        </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse" style={{visibility:"visible"}}>
                            <div className="navbar-nav ms-auto p-4 p-lg-0">
                                <Link to="/" className="nav-item nav-link active">Home</Link>
                                <div className="nav-item dropdown">
                                    <Link to="/" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">About</Link>
                                    <div className="dropdown-menu m-0">
                                        {/* <div onClick={(e)=>scrollToView("about")} className="cursor-pointer dropdown-item">About us</div> */}
                                        <Link to="/prabhupad" className="dropdown-item">Shrila Prabhupad</Link>
                                        <Link to="/chaitanyamahaprabhu" className="dropdown-item">Chaitanya Mahaprabhu</Link>
                                        <Link to="/kamadgiri" className="dropdown-item">Kamadgiri</Link>
                                        <Link to="/chitrakoot" className="dropdown-item">Chitrakoot</Link>
                                    </div>
                                </div>
                                {/* <div onClick={(e)=>scrollToView("contact")} className="cursor-pointer nav-item nav-link">Contact us</div> */}
                                <Link to="/contactus"  className="nav-item nav-link">Contact us</Link>
                                <Link to="/activities"  className="nav-item nav-link">Activities</Link>
                                <Link to="/courses" className="nav-item nav-link">Vedic Courses</Link>
                                {/* <Link to="#" className="nav-item nav-link">Dham Seva</Link> */}
                                <div className="nav-item dropdown">
                                    <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Gallery</Link>
                                    <div className="dropdown-menu m-0">
                                        <Link to="/igallery" className="dropdown-item">Image</Link>
                                        <Link to="/vgallery" className="dropdown-item">Video</Link>
                                    </div>
                                </div>
                                
                                <Link to="/signin" className="nav-item nav-link">Login</Link>
                            </div>
                            <div className="d-none d-lg-flex ms-2">
                                <div className=" cursor-pointer btn btn-outline-primary py-2 px-3" onClick={(e)=>scrollToView("donate")}>
                                    Donate Now
            <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                        <i className="fa fa-arrow-right" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
                {/* Navbar End */}
               
            </div>

        
    );
  }
