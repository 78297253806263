import Spinner from 'react-bootstrap/Spinner';

function Loader() {
  return (
    <  >
      {/* <div classNameName="modal fade show" id="exampleModal" tabindex="-1" aria-hidden="true" style={{ display: "block", margin: "0px auto", backgroundColor: "rgba(5, 0, 0, 0.4)", backdropFilter: "blur(2px)" }} aria-modal="true" role='dialog'>
        <div classNameName="modal-dialog" style={{ position: "absolute", top: "47%", left: "45%", msTransform: "translateY(-50%)", transform: "translateY(-50%)" }}>
          <Spinner animation="border" role="status" size="lg" >
            <span classNameName="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </div> */}
      <div id="spinner" style={{backgroundColor: "rgba(5, 0, 0, 0.4)", backdropFilter: "blur(2px)"}} className="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div className="spinner-border text-light" role="status" style={{height:"3rem",width:"3rem"}}></div>
    </div>
    </>
  );
}

export default Loader;