import React from 'react'
import { Link } from 'react-router-dom';
export default function BackToTop() {
  return (
    <div>
         {/* Back to Top */}
         <Link to="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up" /></Link>
           
    </div>
  )
}
