
import { NavMenu } from '../../components/general/common/NavMenu'
import Footer from '../../components/general/common/Footer'
import  Slider  from '../../components/general/Slider'
import Donate from '../../components/general/Donate'
import About from '../../components/general/About'
import BackToTop  from '../../components/general/BackToTop'
//import  Other  from '../../components/general/Other'
import  Mission  from '../../components/general/Mission'
import  Services  from '../../components/general/Services'
import ContactUs from '../../components/general/ContactUs'
import Notification from '../../components/general/Notification'
import Actvities from './Activities'
import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (

    <div>
      <NavMenu/>
        <Slider/>
        <Notification/>
        <About/>
        <Mission/>
        <Donate/>
        <Services/>
        {/* <Actvities/> */}
        <ContactUs/>
        <BackToTop/>
        <Footer/>
        
    </div>
);
}
}