
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
// import { images, CustomImage } from "./images";
import Footer from '../../components/general/common/Footer'
import { NavMenu } from '../../components/general/common/NavMenu'
import { Link } from "react-router-dom";
import { Image } from "react-grid-gallery";
import React, { useState, useEffect } from 'react'
import callMyApi from '../../ApiCaller';



export default function App() {
  const [showSpinner, setShowSpinner] = useState(false);
  const [data, setData] = useState([]);
  const [page_size, setPaze_size] = useState(15);
  const [current_page, setCurrent_paze] = useState(1);

  async function getData() {
    setShowSpinner(true);
    await callMyApi('admin/api/igallery?page=' + current_page + '&page_size=' + page_size, 'GET')
      .then(res => {
        if (data) {
          setData(data.concat(res.record_per_page));
          console.log(data)
          setShowSpinner(false)
        } else {
          console.log('Unable to get notification.')
        }
      }).catch(e => {
        console.log('Something went wrong, Please try again later.')
      });
  }
  const loadMore = () => {
    setPaze_size(page_size+ 15)
    setCurrent_paze(current_page + 1)
    }
  useEffect(() => {
    getData();
  }, [page_size, current_page]);
  var img = []
  for (let i = 0; i < data.length; i++) {
    let obj = data[i];
    img[i] = {
     
      src: obj.filePath,
      //original: obj.filePath,
      width: "100%",
      height: "100%",
      caption: obj.title,
      description: obj.description,
    }
  }

  const [index, setIndex] = useState(-1);
  const currentImage = img[index];
  const nextIndex = (index + 1) % img.length;
  const nextImage = img[nextIndex] || currentImage;
  const prevIndex = (index + img.length - 1) % img.length;
  const prevImage = img[prevIndex] || currentImage;
  const handleClick = (index: number, item: CustomImage) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);
  return (
    <div>
      <NavMenu />
      {/* Page Header Start */}
      <div className="container-fluid page-header mb-5 wow fadeIn" style={{ background: "linear-gradient(rgba(0, 29, 35, .8), rgba(0, 29, 35, .8)), url('../../img/ckt/kamadgiri.jpeg') center center no-repeat", backgroundSize: "cover" }} data-wow-delay="0.1s">
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">Image Gallery</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item"><Link className="text-white" to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link className="text-white" to="/igallery">Gallery</Link></li>
              <li className="breadcrumb-item text-primary active" aria-current="page">Image Gallery</li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {data && data.length>0 ? <> <div className="container-xxl py-3">
        <div className="container">
          <Gallery
            images={img}
            onClick={handleClick}
            enableImageSelection={false}
            imageTitle="aaa"

          />
          {!!currentImage && (
            /* @ts-ignore */
            <Lightbox
              mainSrc={currentImage.src}
              imageTitle={currentImage.caption}
              imageCaption={currentImage.description}
              mainSrcThumbnail={currentImage.src}
              nextSrc={nextImage.src}
              nextSrcThumbnail={nextImage.src}
              prevSrc={prevImage.src}
              prevSrcThumbnail={prevImage.src}
              onCloseRequest={handleClose}
              onMovePrevRequest={handleMovePrev}
              onMoveNextRequest={handleMoveNext}
            />
          )}</div></div>

<center><Link className="btn btn-primary py-2 px-3 me-3" to="#" onClick={loadMore}>
        View More
        <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
          <i className="fa fa-arrow-right" />
        </div>
      </Link></center></>:
      <><img style={{maxHeight:"300px"}}className="mx-auto" src="../../img/no data.png" /><h3 className="text-center text-2xl text-primary pb-5">No Records Found</h3></>
      }
      <Footer />
    </div>
  );
}

// export interface CustomImage extends Image {
//   original: string;
// }

// export const images: CustomImage[] = [
//   {
//     src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
//     original: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",

//     tags: [
//       { value: "Nature", title: "Nature" },
//       { value: "Flora", title: "Flora" },
//     ],
//     caption: "After Rain (Jeshu John - designerspics.com)",
//   },
//   {
//     src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
//     original: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",

//     caption: "Boats (Jeshu John - designerspics.com)",
//   },
//   {
//     src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
//     original: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
//     width: 320,
//     height: 212,
//     caption: "Color Pencils (Jeshu John - designerspics.com)",
//   },
//   {
//     src: "https://c7.staticflickr.com/9/8546/28354329294_bb45ba31fa_b.jpg",
//     original: "https://c7.staticflickr.com/9/8546/28354329294_bb45ba31fa_b.jpg",
//     width: 320,
//     height: 213,
//     caption: "Red Apples with other Red Fruit (foodiesfeed.com)",
//   },
//   {
//     src: "https://c6.staticflickr.com/9/8890/28897154101_a8f55be225_b.jpg",
//     original: "https://c6.staticflickr.com/9/8890/28897154101_a8f55be225_b.jpg",
//     width: 320,
//     height: 183,
//     caption: "37H (gratispgraphy.com)",
//   },
//   {
//     src: "https://c5.staticflickr.com/9/8768/28941110956_b05ab588c1_b.jpg",
//     original: "https://c5.staticflickr.com/9/8768/28941110956_b05ab588c1_b.jpg",
//     width: 240,
//     height: 320,
//     tags: [{ value: "Nature", title: "Nature" }],
//     caption: "8H (gratisography.com)",
//   },
//   {
//     src: "https://c3.staticflickr.com/9/8583/28354353794_9f2d08d8c0_b.jpg",
//     original: "https://c3.staticflickr.com/9/8583/28354353794_9f2d08d8c0_b.jpg",
//     width: 320,
//     height: 190,
//     caption: "286H (gratisography.com)",
//   },
//   {
//     src: "https://c8.staticflickr.com/9/8104/28973555735_ae7c208970_b.jpg",
//     original: "https://c8.staticflickr.com/9/8104/28973555735_ae7c208970_b.jpg",
//     width: 320,
//     height: 213,
//     caption: "Flower Interior Macro (Tom Eversley - isorepublic.com)",
//   },
//   {
//     src: "https://c4.staticflickr.com/9/8562/28897228731_ff4447ef5f_b.jpg",
//     original: "https://c4.staticflickr.com/9/8562/28897228731_ff4447ef5f_b.jpg",
//     width: 320,
//     height: 194,
//     caption: "Old Barn (Tom Eversley - isorepublic.com)",
//   },
//   {
//     src: "https://c2.staticflickr.com/8/7577/28973580825_d8f541ba3f_b.jpg",
//     original: "https://c2.staticflickr.com/8/7577/28973580825_d8f541ba3f_b.jpg",
//     alt: "Cosmos Flower",
//     width: 320,
//     height: 213,
//     caption: "Cosmos Flower Macro (Tom Eversley - isorepublic.com)",
//   },
//   {
//     src: "https://c7.staticflickr.com/9/8106/28941228886_86d1450016_b.jpg",
//     original: "https://c7.staticflickr.com/9/8106/28941228886_86d1450016_b.jpg",
//     width: 271,
//     height: 320,
//     caption: "Orange Macro (Tom Eversley - isorepublic.com)",
//   },

// ];