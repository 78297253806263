
import React, { Component } from 'react'
import Footer from '../../components/general/common/Footer'
import { NavMenu } from '../../components/general/common/NavMenu'

export default class Chitrakoot extends Component {
    render() {
        return (
<div>
    <NavMenu />

    {/* Page Header Start */}
    <div className="container-fluid page-header mb-5 wow fadeIn" style={{background: "linear-gradient(rgba(0, 29, 35, .8), rgba(0, 29, 35, .8)), url('../../img/ckt/cktback1.jpeg') center center no-repeat", backgroundSize:"cover"}} data-wow-delay="0.1s">
        <div className="container text-center">
            <h1 className="display-4 text-white animated slideInDown mb-4">Chitrakoot</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb justify-content-center mb-0">
                    <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
                    <li className="breadcrumb-item"><a className="text-white" href="#">About</a></li>
                    <li className="breadcrumb-item text-primary active" aria-current="page">Chitrakoot</li>
                </ol>
            </nav>
        </div>
    </div>
    {/* Page Header End */}

    <div className="container-xxl py-3">
        <div className="container">
            <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{}}>
                <div className="row bg-light border-2 border-primary rounded p-4 mb-4" style={{ border: " dashed" }}>
                    <div className='col-sm-7'>
                        {/* <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3 display-5">About Chitrakoot</div> */}
                        <p align="justify" >
                            Chitrakoot means the ‘Hill of many wonders’. Chitrakoot falls in the northern Vindhya range of mountains spread over the states of Uttar Pradesh and Madhya Pradesh. Chitrakoot district in Uttar Pradesh was created on 4 September 1998. Chitrakoot Parvat Mala includes Kamad Giri, Hanumaan Dhara, Janki Kund, Lakshman pahari, and Devangana famous Religious mountains.Lord Rama spend a major part of his exile here. According to the epic Ramayana, Chitrakoot is the place where Bharat, brother of Lord Rama came to visit him and asked him to return to Ayodhya and rule the kingdom. It is believed that the supreme Gods of Hinduism, (Brahma, Vishnu, and Shiva) took incarnations here. The place is dotted with many temples and several religious sites. At Chitrakoot, everything relates to Lord Rama. One can also explore the amalgamation of culture and history on this land. Chitrakoot is a spiritual retreat, thronged almost throughout the year by travellers, who have a penchant for the unknown and unexplored. Chitrakoot is a perfect blend of divinity, serenity, and natural beauty.
                        </p>
                    </div>
                    <div className='col-sm-5'>
                        <div className="position-relative h-100">
                            <img className="rounded w-100 h-100" src="../../img/ckt/cktabout.avif" style={{ objectFit: 'cover', maxHeight: '330px' }} alt />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" align="justify">
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">

                                List of Places to Visit
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row">
                                    <div className="col-sm-4">

                                        <strong>
                                            List of places to visit in chitrakoot:-
                                        </strong>
                                        <ul>
                                            <li> Ramghat </li>
                                            <li>Kamadgiri </li>
                                            <li> Bharat Milap </li>
                                            <li>Gupt Godavari </li>
                                            <li>Janki Kund </li>
                                            <li> Saphatik Shila </li>
                                            <li> Sati Anusaya Ashram </li>
                                            <li> Ramdarshan </li>
                                            <li> Ganeshbag </li>
                                            <li> Waterfall </li>
                                            <li>Kalinjar Fort </li>
                                            <li>Hanmuan Dhara </li>
                                        </ul>
                                        {/* <code>.accordion-body</code>, */}

                                    </div>
                                    <div className="col-sm-4 my-1">
                                        <div className="position-relative h-100">
                                            <img className="rounded w-100 h-100" src="../../img/ckt/ckt3.jpeg" style={{ objectFit: 'cover' }} alt />
                                        </div>
                                    </div>
                                    <div className="col-sm-4 my-1">
                                        <div className="position-relative h-100">
                                            <img className="rounded w-100 h-100" src="../../img/ckt/ckt2.jpeg" style={{ objectFit: 'cover' }} alt />
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Accommodations
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="position-relative h-100">
                                            <img className="rounded w-100 h-100" src="../../img/ckt/hotelroom.webp" style={{ objectFit: 'cover' }} alt />
                                        </div>
                                    </div>
                                    <div className="col-sm-8">
                                        <p>  <strong>ISKCON Chitrakoot accommodation facilities:- </strong>
                                            We have many accommodations available in chitrakoot Dham that will suite the needs of anybody. As of now we have facilities to accommodate over 500 pilgrims. We provide the facility of varieties of rooms: standard, deluxe, super deluxe, a/c rooms,  and dormitories to accommodate the pilgrims and visitors to chitrakoot Dham.
                                        </p>
                                        <p> <strong> For all inquiries for accommodations ISKCON Chitrakoot:-</strong>
                                            <br /> Email:info.iskconcktd@gmail.com
                                            <br /> Please call us on: +91 70123 63367
                                        </p>


                                        
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Dining Options
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="row">
                                    <div className="col-sm-8">
                                        <strong>Govinda’s Karma Free Prasadam!, 100% Vegetarian!.</strong><br />
                                        <p>Govinda’s Prasadam is part of a worldwide chain of Restaurants opened by the International Society for Krishna Consciousness (ISKCON). Popularly known as the Hare Krishna movement, ISKCON is a cultural movement dedicated to the re-spiritualisation of society. An important part of this mission is the distribution of sanctified foodstuffs, known as “Krishna-prasad”, which is pure vegetarian food nicely prepared and offered in devotion to Lord Krishna, the Supreme Personality of Godhead. By this process the food is sanctified and one who eats it becomes spiritually benefited. By regularly taking Krishna-prasad one will feel an upliftment of spirit and a diminishing material anxiety. Govindas vegetarian restaurants serve only Krishna-prasad, expertly prepared with love for the pleasure of Sri Krishna. We hope you enjoy your meal and also appreciate the unique spiritual experience of eating Krishna-prasad.
                                        </p>
                                        <strong>What is karma-free food?</strong>
                                        <p>
                                            The Sanskrit word karma means “action”, or more specifically, any material action that brings a reaction that binds us to the material world. Although the idea of karma is generally associated with eastern philosophy, many people in the West are also coming to understand that karma is a natural principle, like time or gravity and no less inescapable. For every action there is a reaction. According to the law of karma, if we cause pain and suffering to other living beings, we must endure pain and suffering in return, both individually and collectively. We reap what we sow, in this life and the next.
                                        </p>
                                        <strong>For booking the Prasadam. Please contact below:-</strong>
                                        <br />ISKCON Chitrakoot,<br />
                                        Email: info.iskconcktd@gmail.com<br />
                                        Please call us on: +91 70123 63367
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="position-relative h-100">
                                            <img className="rounded w-100 h-100" src="../../img/ckt/food.jpeg" style={{ objectFit: 'cover' }} alt />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Travel assistence
                            </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse"  aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="position-relative h-100">
                                            <img className="rounded w-100 h-100" src="../../img/ckt/travel.jpeg" style={{ objectFit: 'cover' }} alt />
                                        </div>
                                    </div>
                                    <div className="col-sm-8">
                                        <h3>How to Reach:-</h3>
                                        <strong>By Air</strong><br />
                                        The nearest airport is at Khajuraho Airport.
                                        <br /> <br /> <strong>By Train</strong><br />
                                        There are 3 railway station connected:<br />
                                        <ol>
                                            <li>
                                                Chitrakoot Dham (Karwi Uttar Pradesh) : Chitrakoot Karwi is at a distance of 10 kms. away from Chitrakoot Dham railway station. It can be reached by bus, taxi or any other means of road transport.
                                            </li><li>
                                                Maghjawan MP Railway station : Chitrakoot is at a distance of 34 km. from Maghjawan Tehsil Main Office which can be reached by bus.
                                            </li><li>
                                                Satna MP Railway station : Satna is main railway station from which chitrakoot is 78 kms. and can be reached bus, personal taxi means are readily available.
                                            </li>
                                        </ol>
                                        <strong>By Road</strong><br />
                                        The distance from Satna district headquarter is 78 Km. Approx.
                                        <br /><br />
                                        <p ><a href="https://holyvoyages.com/madhya-pradesh/top-10-pilgrimage-places-of-chitrakoot-dham-india.aspx">
                                            <strong>Tourist Attractions :-</strong> Bharat Milap Temple , Gupt Godavari , Sati Anusuya Ashram , Ramghat, and Hanuman Dhara , Waterfalls at Marpha , Kamadgiri , Sphatik Shila and Ganesh Bagh.
                                        </a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
</div>
        )
    }
}
