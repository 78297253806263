import React, { useEffect, Fragment, useRef, useState } from 'react'
import { json, useNavigate } from "react-router-dom"
import { Dialog, Transition } from '@headlessui/react'
import { TrophyIcon } from '@heroicons/react/24/outline'
import Calendar from 'react-calendar';
const ModalContent = ({ openModal, setOpenModal, selectModalData, editModal, handleChangeSeletedDate, setSelectModalData, handleAction, newAddRecord }) => {


  return (
    <><Transition.Root show={openModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={(e) => setOpenModal(!openModal)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-3  pb-4 pt-2 sm:p-6 sm:pb-1">
                  <div className="">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <TrophyIcon className="h-6 w-6 text-orange-500" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {editModal ? selectModalData.showMessage : selectModalData.showMessage}
                      </Dialog.Title>
                      <div className="mt-2">
                        {editModal ? (selectModalData && <>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            {console.log(selectModalData.showMessage)}
                            {editModal && selectModalData.showMessage == 'Update Record' ? <div className="col-span-6">
                              <label htmlFor="file" className="block text-left text-sm font-medium leading-6 text-gray-900 items-center">
                                Current Image
                              </label>
                              <img src={selectModalData.filePath} alt='slide_image' height="45px" width="75px" />
                            </div> : <></>}


                            <div className={editModal && selectModalData.showMessage == 'Update Record' ? 'col-span-6' : 'col-span-12'}>
                              <label htmlFor="file" className="flex flex-center block text-left text-sm font-medium leading-6 text-gray-900">
                                Choose Course Banner <abbr title='Please keep image resolution to 350 x 250 pixels or in proportional and keep same resulution for all banner images.'> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
</svg></abbr>
                              </label>
                              <div className="">
                                <input
                                  type="file"
                                  name="file"
                                  id="file"
                                  // value={selectModalData.file}
                                  onChange={(e) => setSelectModalData({ ...selectModalData, file: e.target.files[0] })}
                                  autoComplete="given-name"
                                  className="block px-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring- ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-2"
                                />
                              </div>
                            </div>

                            <div className="col-span-12">
                              <label htmlFor="first-name" className="flex flex-center block text-left text-sm font-medium leading-6 text-gray-900">
                                Title <abbr title='Please keep title to 30 characters or less.'> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
</svg></abbr>
                              </label>
                              <div >
                                <input
                                  type="text"
                                  name="title"
                                  required="true"
                                  id="title"
                                  placeholder='Enter course title'
                                  value={selectModalData.title}
                                  onChange={(e) => setSelectModalData({ ...selectModalData, title: e.target.value })}
                                  autoComplete="given-name"
                                  className="block px-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                            <div className="col-span-12">
                              <label htmlFor="first-name" className="flex flex-center block text-left text-sm font-medium leading-6 text-gray-900">
                                Description  <abbr title='Please keep description to 115 characters or less.'> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
</svg></abbr>
                              </label>
                              <div className="mt-2">
                                <textarea
                                  name="description"
                                  id="description"
                                  rows={3}
                                  value={selectModalData.description}
                                  placeholder='Enter description'
                                  onChange={(e) => setSelectModalData({ ...selectModalData, description: e.target.value })}
                                  autoComplete="given-name"
                                  className="block px-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                           

                           

                            <div className="col-span-12">
                              <label htmlFor="first-name" className="flex flex-center block text-left text-sm font-medium leading-6 text-gray-900">
                                Date  <abbr title='Please select date.'> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
</svg></abbr>
                              </label>
                              <div>
                                <input type="date" name="date" id="date" placeholder='Enter course date' value={selectModalData.date}
                                  onChange={(e) => setSelectModalData({ ...selectModalData, date: e.target.value })}
                                  autoComplete="given-name"
                                  className="block px-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                            <div className="col-span-12">
                              <label htmlFor="first-name" className="flex flex-center block text-left text-sm font-medium leading-6 text-gray-900">
                                Time  <abbr title='Please select time.'> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
</svg></abbr>
                              </label>
                              <div>
                                <input type="time" name="time" id="time" placeholder='Enter course start time' value={selectModalData.time}
                                  onChange={(e) => setSelectModalData({ ...selectModalData, time: e.target.value })}
                                  autoComplete="given-name"
                                  className="block px-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                          </div>
                          <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button
                              type="button"
                              className="mt-3 items-center  inline-flex w-full justify-center rounded-md bg-orange-400 px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                              onClick={(e) => handleAction(e, selectModalData, `${newAddRecord ? "add_record" : "update"}`)}
                            >
                              {newAddRecord ? "Submit" : "Update"}
                            </button>
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                              onClick={(e) => handleAction(e, selectModalData, 'close')}
                            >
                              Cancel
                            </button>
                          </div>
                        </>) : (
                          <>
                            <div className="col-span-full">
                              <label htmlFor="about" className="mt-2 mb-3 block text-left text-sm font-medium leading-6 text-gray-900">
                                Are you sure want to delete this record ?
                              </label>

                            </div>
                            <div className="col-span-full">
                              <label htmlFor="about" className="mt-2  block text-left text-sm font-medium leading-6 text-gray-900">
                                Title : {selectModalData.title}
                              </label>
                            </div>
                            <div className="col-span-full">
                              <label htmlFor="about" className="mt-2  block text-left text-sm font-medium leading-6 text-gray-900">
                                Description : {selectModalData.description}
                              </label>
                            </div>

                            <div className="col-span-full">
                              <label htmlFor="about" className="mt-2 block text-left text-sm font-medium leading-6 text-gray-900">
                                Date Time : {selectModalData.date} {selectModalData.time} IST
                              </label>

                            </div>

                            <div className="col-span-full">
                              <label htmlFor="about" className="mt-2 block text-left text-sm font-medium leading-6 text-gray-900">
                                Date Time : {selectModalData.datetime}
                              </label>

                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                              <button
                                type="button"
                                className="mt-3 items-center inline-flex w-full justify-center rounded-md bg-orange-400 px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                                onClick={(e) => handleAction(e, selectModalData, "delete")}
                              >
                                Delete
                              </button>
                              <button
                                type="button"
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                onClick={(e) => handleAction(e, selectModalData, "close")}
                              >
                                Cancel
                              </button>
                            </div>

                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root></>
  )
}

export default ModalContent;