import React, { useState, useEffect } from 'react'
import callMyApi from '../../ApiCaller';


export default function Notification() {
    const [showSpinner, setShowSpinner] = useState(false);
    const [data, setData] = useState([]);
    async function getData() {

        setShowSpinner(true);
        await callMyApi(`admin/api/notification`, 'GET')
            .then(res => {
                if (data) {
                    setData(res.record_per_page);
                } else {
                    console.log('Unable to get notification.')

                }
            }).catch(e => {

                console.log('Something went wrong, Please try again later.')

            });

    }
    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            {data && data.length > 0 ? <div className='bg-dark'>
                <div className="container">
                    <div className="text-center mx-auto wow fadeInRight pt-2 text-lg text-primary" data-wow-delay="0.15s">
                        <marquee >
                            {data.map(item => {
                                return <span className='inline-flex items-center' key={item.id}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                                    </svg> &nbsp;
                                    {item.url.length > 1 ? <a href={item.url} >{item.notification}</a>: <a  >{item.notification}</a>}
                                    &emsp;&emsp;</span>
                            })}
                        </marquee>
                    </div>
                </div>
            </div> : <></>}
        </>
    )
}
