import React, { Component } from 'react'

import Layout from '../../components/admin/Layout'
import Footer from '../../components/admin/Footer'

export default class Chitrakoot extends Component {
    render() {
        return (
            <div className='container-fluid'>
                <Layout />
                <div className='row' style={{ paddingTop: "5em" }}>
                    <h1>Dev in progress</h1>
                </div>
                <Footer/>
            </div>
        )
    }
}
