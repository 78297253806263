import React from 'react'
import { NavMenu } from '../../components/general/common/NavMenu'
import  Footer  from '../../components/general/common/Footer'
import  Login from '../../components/general/Login'



export default function SignIn() {
  return (
    <>
        <Login/>
    </>
  )
}
