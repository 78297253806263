
import React, { Component } from 'react'
import Footer from '../../components/general/common/Footer'
import {NavMenu} from '../../components/general/common/NavMenu'
import { Link } from 'react-router-dom'
export default class Courses extends Component {
  render() {
    return (
      <div>
<NavMenu/>

   {/* Page Header Start */}
   <div className="container-fluid page-header mb-5 wow fadeIn" style={{background: "linear-gradient(rgba(0, 29, 35, .8), rgba(0, 29, 35, .8)), url('../../img/ckt/kamadgiri.jpeg') center center no-repeat", backgroundSize:"cover"}} data-wow-delay="0.1s">
  <div className="container text-center">
    <h1 className="display-4 text-white animated slideInDown mb-4">Bhagwan Kamadgiri</h1>
    <nav aria-label="breadcrumb animated slideInDown">
      <ol className="breadcrumb justify-content-center mb-0">
        <li className="breadcrumb-item"><Link className="text-white" to="#">Home</Link></li>
        <li className="breadcrumb-item"><Link className="text-white" to="#">About</Link></li>
        <li className="breadcrumb-item text-primary active" aria-current="page">Bhagwan Kamadgiri</li>
      </ol>
    </nav>
  </div>
</div>
{/* Page Header End */}


<div className="container-xxl py-3">
        <div className="container">
            <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{}}>
                <div className="row bg-light border-2 border-primary rounded p-4 mb-4" style={{ border: " dashed" }}>
                <div className='col-sm-5'>
                        <div className="position-relative h-100">
                            <img className="rounded w-100 h-100" src="../../img/ckt/kamadgiri1.jpeg" style={{ objectFit: 'cover', maxHeight: '330px' }} alt />
                        </div>
                    </div>
                    <div className='col-sm-7'>
                        {/* <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3 display-5">About Chitrakoot</div> */}
                        <p align="justify" >
                        Kamadgiri is the main holy place of Chitrakoot Dham . The Sanskrit word ‘Kamadgiri’ means the mountain which fulfills all the wishes and desires. The place is believed to have been the abode of Lord Ram, Sita and Laxman during their exile. Lord Kamtanath, another of His names, is the Ishta Deva principal deity not only of Kamadgiri Parvat but of the whole of Chitrakoot. The religious-minded believe that all the holy places (i.e. teerthas) are in the Parikrama or its pilgrimage-path. There are a large number of temples in the Parikrama Path. Except during the summers, the place remains green throughout the year and appears like a bow seen from any place in Chitrakoot.
<br/>
Kamadgiri Hill is one of the most revered places in the state of Uttar Pradesh. Pilgrims to this religious site often perform a circumambulation to receive blessings. Situated in the southern part of the state, it is the holiest place in Chitrakoot Dham. Like most of the religious places in India, it is associated with several mythological legends. It is a Sanskrit word which means "one that fulfills all desires". The principal deity of Chitrakoot is Lord Kamtanath.


                        </p>
                    </div>
                    
                </div>
            </div>
            <div className="row" align="justify">
                <div class="accordion" id="accordionExample">

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Parikrama
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div class="row">
                                    <div class="col-sm-8">
                                    There is a pilgrimage path enveloping this hill, whose distance is about 5 km approx. Devotees believe that all the teerthas, or, holy places, lie along the religious route. Even today devotees perform the sacred parikrama of Kamadgiri which is around five kilometres long. 
There are several temples on the path of the Parikrama, one of the most famous being Bharat Milaap temple, where Bharat met Lord Ram and convinced him to come back to his kingdom.
                                    </div>
                                    <div class="col-sm-4">
                                        <div className="position-relative h-100">
                                            <img className="rounded w-100 h-100" src="../../img/ckt/kamadgiri parikrama chitrakoot (24).jpg" style={{ objectFit: 'cover' }} alt />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                               History
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div className="position-relative h-100">
                                            <img className="rounded w-100 h-100" src="../../img/ckt/kamadgiri.jpeg" style={{ objectFit: 'cover' }} alt />
                                        </div>
                                    </div>
                                    <div class="col-sm-8">
                                    According to one of them, the Kamadgiri Mountain is accorded the highest importance because it was here that Lord Brahma performed the first yagna before the creation of life (shristi). According to various mythological sources, this was the place where Lord Rama and Goddess Sita spend twelve years of their exile. Tourists who have visited this popular tourist destination in Uttar Pradesh say that it exudes a spiritual aura. A large part of this can also be attributed to the amazing landscape of this place, which is characterized by dense forests and vegetation.
                                    <p>Devotees visiting Kamadgiri, which is counted among the popular tourist attractions in Uttar Pradesh, often walk barefoot around this hill. Doing so is believed to bring about good luck and wash away one’s sins. According to historical sources, the passage which is used for circumambulation was constructed by Bundelkhand kings. Performing the parikrama takes devotees around three to four hours. Apart from the religious significance which this place holds, it is also famous for its serenity and ambiance. If you want to spend a day in complete peace and meditation, then you should visit this religious destination in Chitrakoot.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<Footer/>

      </div>
    )
  }
}
