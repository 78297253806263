import React, { Component } from 'react'
import Footer from '../../components/general/common/Footer'
import { NavMenu } from '../../components/general/common/NavMenu'
import Testimonial from '../../components/general/Testimonial'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class Prabhupad extends Component {
    render() {
        return (
            <div>
                <NavMenu />
                {/* Page Header Start */}
                <div className="container-fluid page-header mb-5 wow fadeIn" style={{ background: "linear-gradient(rgba(0, 29, 35, .8), rgba(0, 29, 35, .8)), url('../../img/prabhupad.jpeg') center center no-repeat", backgroundSize: "cover" }} data-wow-delay="0.1s">
                    <div className="container text-center">
                        <h1 className="display-4 text-white animated slideInDown mb-4">A.C. Bhaktivedanta Swami Prabhupada</h1>
                        <nav aria-label="breadcrumb animated slideInDown">
                            <ol className="breadcrumb justify-content-center mb-0">
                                <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
                                <li className="breadcrumb-item"><a className="text-white" href="#">About</a></li>
                                <li className="breadcrumb-item text-primary active" aria-current="page">Prabhupad</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                {/* Page Header End */}

                <div className="container-xxl py-3">
                    <div className="container">
                        <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{}}>
                            <div className="row bg-light border-2 border-primary rounded p-4 mb-4" style={{ border: " dashed" }}>
                                <div className='col-sm-5'>
                                    <div className="position-relative h-100">
                                        <img className="rounded w-100 h-100" src="../../img/prabhupad1.jpeg" style={{ objectFit: 'cover', maxHeight: '630px' }} alt="" />
                                    </div>
                                </div>
                                <div className='col-sm-7' align="justify">
                                    {/* <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3 display-5">About Chitrakoot</div> */}
                                    <h4> His Divine Grace A.C. Bhaktivedanta Swami Prabhupada</h4>
                                    <p  >
                                        His Divine Grace A. C. Bhaktivedanta Swami Prabhupada, Founder-Acharya of International Society for Krishna Consciousness (ISKCON), was born Abhay Charan De, on the 1 September 1896, in Calcutta. In 1922 he met His Divine Grace Bhaktisiddanta Sarasvati Thakur, Founder of Gaudiya Math, who requested Abhay to broadcast Vedic knowledge in the English medium. In 1933, at Allahabad, Abhay was formally initiated and made it his life ambition to expound the Vedic conclusion that real freedom means liberation from the miseries of material life : birth, death, old age and disease, a state that can be permanently attained by awakening one’s pure love for God, Krishna-prema or Krishna-bhakti.

                                        In the ensuing years Abhay Charanaravinda (his initiated name), wrote a commentary on the Bhagavad-gita. In 1944 he started the Back to Godhead magazine, which to this day is being continued by his disciples.

                                    </p><p>In recognition of his philosophical knowledge and devotion the Gaudiya Vaishnava Society honoured him with the title Bhaktivedanta in 1947. Following his retirement from married life, A.C. Bhaktivedanta travelled to Vrindavan where he lived in the humble surroundings of the Radha Damodar temple. In 1959 he took the sannyasa order of life and, as A.C. Bhaktivedanta Swami, started his work on the multi-volume translation and commentary of the 18,000 verse Srimad Bhagavatam. In 1965, at the age of 69, when ordinary persons were thinking of retirement, he went to the United States to fulfil the mission of his spiritual master and founded ISKCON.

                                        He brought to the West the divine teachings of Lord Caitanya Mahaprabhu who taught the public glorification of Hare Krishna mantra. Srila Prabhupada, (as he was affectionately called by his followers), taught on a non-sectarian level that every living being is an eternal servant of Lord Krishna with a dormant natural propensity to experience the eternal bliss of pure love of God.
                                        <br />


                                    </p>
                                </div>

                            </div>
                        </div>
                        
                      

                    </div>
                </div>
              <div className="container-xxl py-5" align="justify">
              

  <div className="container wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth:"1100px"}}>
    <div  className="text-center mx-auto mb-5"  >
      <h1 className="display-6 mb-5">Famous people speaks about Srila Prabhupada</h1>
    </div>
    <Testimonial/>
    </div> 
    </div>
                <Footer />

            </div>
        )
    }
}
