import React from 'react'
import './index.css'
import { Link, useNavigate } from "react-router-dom";

export default function Layout() {
    let navigate = useNavigate();

    const handleRedirect = (e) => {
        localStorage.removeItem("login-info");
        return navigate("/signin");
    }
    return (
        <div>
            <div className="bg-dark offcanvas offcanvas-start w-44" tabIndex={-1} id="offcanvas" data-bs-keyboard="false" data-bs-backdrop="false">
                <div className="offcanvas-header">
                    <h3 className="offcanvas-title d-sm-block text-light" id="offcanvas">IskconChitrakut</h3>
                    <Link to={"/"} >
                        <button type="button" className="bi bi-x-circle btn-lg text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </Link>
                </div>
                <div className="offcanvas-body px-0 py-0">
                    <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start" id="menu">
                        {/* <li className="nav-item">
                            <Link to="/admin" className="nav-link text-truncate">
                                <i className="fs-5 bi-house" /><span className="ms-1  d-sm-inline">Home Page</span>
                            </Link>
                        </li> */}
                        <li>
                            <Link to="/admin/dashboard" className="nav-link text-truncate">
                                <i className="fs-5 bi-speedometer2" /><span className="ms-1 d-sm-inline">Dashboard</span> </Link>
                        </li>
                        <li>
                            <Link to="/admin/slider" className="nav-link text-truncate">
                                <i className="fs-5 bi-people" /><span className="ms-1 d-sm-inline">Slider</span> </Link>
                        </li>
                        <li>
                            <Link to="/admin/notification" className="nav-link text-truncate">
                                <i className="fs-5 bi-people" /><span className="ms-1 d-sm-inline">Notification</span> </Link>
                        </li>
                        {/* <li>
                            <Link to="/admin/courses" className="nav-link text-truncate">
                                <i className="fs-5 bi-table" /><span className="ms-1 d-sm-inline">Courses</span></Link>
                        </li> */}
                        <li>
                            <Link to="/admin/contactus" className="nav-link text-truncate">
                                <i className="fs-5 bi-table" /><span className="ms-1 d-sm-inline">Contact Us</span></Link>
                        </li>
                        

                        <li className="dropdown">
                            <Link to="#" className="nav-link dropdown-toggle  text-truncate" id="dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fs-5 bi-bootstrap" /><span className="ms-1 d-sm-inline">Courses</span>
                            </Link>
                            <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdown">
                                <li><Link className="dropdown-item" to="/admin/courses">Course List</Link></li>
                                <li><Link className="dropdown-item" to="/admin/registrations">Registrations</Link></li>
                                {/* <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li><Link className="dropdown-item" to="#">Registered</Link></li> */}
                            </ul>
                        </li>
                        <li>
                            <Link to="/admin/activities" className="nav-link text-truncate">
                                <i className="fs-5 bi-people" /><span className="ms-1 d-sm-inline">Activities</span> </Link>
                        </li>
                        <li className="dropdown">
                            <Link to="#" className="nav-link dropdown-toggle  text-truncate" id="dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fs-5 bi-bootstrap" /><span className="ms-1 d-sm-inline">Gallery</span>
                            </Link>
                            <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdown">
                                <li><Link className="dropdown-item" to="/admin/igallery">Image Gallery</Link></li>
                                <li><Link className="dropdown-item" to="/admin/vgallery">Video Gallery</Link></li>
                                {/* <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li><Link className="dropdown-item" to="#">Registered</Link></li> */}
                            </ul>
                        </li>
                        <li>
                            <Link to="/admin" className="nav-link text-truncate">
                                <i className="fs-5 bi-table" /><span className="ms-1 d-sm-inline">News Letter</span></Link>
                        </li>
                        <li>
                            <Link to="/admin/email" className="nav-link text-truncate">
                                <i className="fs-5 bi-grid" /><span className="ms-1 d-sm-inline">Email</span></Link>
                        </li>

                    </ul>
                </div>
            </div>

            <div className="row">

                <div className="container-fluid  fixed-top px-0 wow fadeIn bg-dark" data-wow-delay="0.1s">
                    <nav className="navbar navbar-light">
                        <div className="container-fluid">
                            <button className="btn float-start" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" role="button">
                                <i className="bi bi-list fs-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" />
                            </button>
                            <div className="nav-item dropdown d-flex" style={{ float: "right" }}>
                                <Link to="#" className="nav-link dropdown-toggle  " data-bs-toggle="dropdown">
                                    <img src="img/iskconLogo.webp" style={{ maxHeight: "30px", borderRadius: "10px", display: "inline" }} />
                                    &emsp;Admin</Link>
                                <div className="dropdown-menu m-0" >
                                    <Link to="/igallery" className="dropdown-item">Profile</Link>
                                    <Link to="/vgallery" className="dropdown-item">Settings</Link>
                                    <hr className="dropdown-divider" />

                                    <div onClick={(e) => handleRedirect(e)} className="cursor-pointer dropdown-item">Logout</div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}
