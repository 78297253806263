import React from 'react'
import { Link } from 'react-router-dom';
export default function Services() {
  return (
    <div>{/* Service Start */}
    <div id="" className="container-xxl py-5">
     <div className="container">
         <div className="text-center mx-auto mb-5 wow fadeInUp mt-3" data-wow-delay="0.1s" style={{ maxWidth: 500 }}>
             <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3 display-5">What We Do</div>
             <h4 className="mb-5">Learn More What We Do And Get Involved</h4>
         </div>
         <div className="row g-4 justify-content-center" >
             <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s" >
                 <div className="service-item  text-center h-100 p-3 p-xl-5">
                     <img className="img-fluid mb-4" src="img/service/service1.jpeg" style={{borderRadius:"50%", border:"3px solid #FF6F0F"}} alt="" />
                     <h4 className="mb-3">Bhakti Vriksha</h4>
                     <p className="mb-4" >Understanding Bhagwad Gita and apply its teachings in our daily lives.</p>
                     <Link className="btn btn-outline-primary px-3" to="#">
                         Learn More
    <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                             <i className="fa fa-arrow-right" />
                         </div>
                     </Link>
                 </div>
             </div>
             <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                 <div className="service-item  text-center h-100 p-4 p-xl-5">
                     <img className="img-fluid mb-4" src="img/service/service2.webp" style={{borderRadius:"50%", border:"3px solid #FF6F0F"}} alt="" />
                     <h4 className="mb-3">Annadana Seva</h4>
                     <p className="mb-4">Anna Daan is a way to please Lord Krishna. Anna daan is maha daan.</p>
                     <Link className="btn btn-outline-primary px-3" to="#">
                         Learn More
    <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                             <i className="fa fa-arrow-right" />
                         </div>
                     </Link>
                 </div>
             </div>
             <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                 <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                     <img className="img-fluid mb-4" src="img/service/service3.webp" style={{borderRadius:"50%", border:"3px solid #FF6F0F"}} alt="" />
                     <h4 className="mb-3">Book Distribution</h4>
                     <p className="mb-4">Distribute Vedic literature and books written or translated by Prabhupada.</p>
                     <Link className="btn btn-outline-primary px-3" to="#">
                         Learn More
    <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                             <i className="fa fa-arrow-right" />
                         </div>
                     </Link>
                 </div>
             </div>
             <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                 <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                     <img className="img-fluid mb-4" src="img/service/service4.webp" style={{borderRadius:"50%", border:"3px solid #FF6F0F"}} alt="" />
                     <h4 className="mb-3">Home Programs</h4>
                     <p className="mb-4">Book online or Call to invite vaishnavas to conduct your Home Programs and Yagnas.</p>
                     <Link className="btn btn-outline-primary px-3" to="#">
                         Learn More
    <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                             <i className="fa fa-arrow-right" />
                         </div>
                     </Link>
                 </div>
             </div>
         </div>
     </div>
    </div>
    {/* Service End */}</div>
  )
}
