import Footer from '../../components/general/common/Footer'
import { NavMenu } from '../../components/general/common/NavMenu'

import React, { useState } from 'react'
import { json, useNavigate } from "react-router-dom"
import Alert from '../../components/general/common/Alert';
import callMyApi from '../../ApiCaller';
import { useSearchParams } from "react-router-dom";
import { validEmail, validMessage, validName, validComment, validAddress, validMobno,validLanguage } from '../../Rejex';


export default function CoursesRegister(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobno, setMobNo] = useState("");
  const [course_type, setCourseType] = useState(searchParams.get('type'));
  const [course_name, setCourseName] = useState(searchParams.get('name'));
  const [course_id, setCourseId] = useState(searchParams.get('id'));
  const [language, setLanguage] = useState("");
  const [address, setAddress] = useState("");
  const [comment, setComment] = useState("");
  const [response, setResponse] = useState("");
  const [showAlart, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertMessageType, setAlertMessageType] = useState("");
  const [alertCategory, setAlartCategory] = useState(null);
  const[showSpinner,setShowSpinner]=useState(false)
  
  const navigate = useNavigate();
  
  let formErrors = [];
  const updateShowAleart = (props) => {
    setShowAlert(false)
  }

  function validate() {
    let status = false;
    formErrors=[];
    console.log(name);
    if (!validName.test(name)) {
      formErrors.push('Name must be between 4 to 50 charectors.');
      status = true;
    } 
    if (!validEmail.test(email)) {
      formErrors.push('Invalid email id format.');
      status = true;
    }
    // if (!validMobno.test(mobno)) {
    //   formErrors.push('Invalid mobile number.');
    //   status = true;
    // }
    if (!validAddress.test(address)) {
      formErrors.push('Address must be between 10 to 350 charectors, excluding special charectors.');
      status = true;
    }
    if (!validComment.test(comment)) {
      formErrors.push('Messsage must be upto 500 charectors.');
      status = true;
    }
    if (!validLanguage.test(language)) {
      formErrors.push('You must to select your prefered language.');
      status = true;
    }
    return status;
  }


  const updateShowAlert = (props) => {
    setShowAlert(false)
  }
  async function register() {
    let validateError = validate();
    if (validateError) {

      setShowAlert(true);
      setAlertMessage(formErrors);
      setAlertMessageType("danger");
      setAlartCategory("Error");

    } else {
    setCourseType(searchParams.get('type'));
    setCourseName(searchParams.get('name'));
    setCourseId(searchParams.get('id'));

    console.warn(email);
    let data = { name, email, mobno, course_type, course_name, course_id, language, address, comment }
    setShowSpinner(false)
    await callMyApi('general/api/courseregister/', data, 'POST')
    .then(data => {
      console.log('success');
      setShowAlert(true);
      setAlertMessageType('success');
      setAlertMessage('Thank you ' + name + ' for registration. You are registered successfully.')

    }).catch(e => {
      console.log(e)
      console.log('danger');
      setShowAlert(true);
      setAlertMessageType('danger');
      setAlertMessage('Something went wrong, Please try again later.')
    });
    setShowSpinner(false)
    // if (res.status == "201") {
    //   setShowAlert(true);
    //     setAlertMessageType('success');
    //     setAlartCategory('Info')
    //     setAlertMessage('Thank you ' + res.body.name + ' for registration. You are registered successfully.')
    //   //console.log("success");
    //   //navigate('/admin');
    // } else if (res.body.userMessage == "Invalid user id or password.") {
    //   setShowAlert(true);
    //   console.log("invalid");
    // }

  }}


  return (
    <div>
      <NavMenu />

      {/* Page Header Start */}
      <div className="container-fluid page-header mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">Register for Vedic Courses</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
              <li className="breadcrumb-item"><a className="text-white" href="#">Courses</a></li>
              <li className="breadcrumb-item text-primary active" aria-current="page">CoursesRegister</li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}


      {/* CoursesRegister Start */}

      <div className="col-lg-3 " ></div>
      <div id="donate" className="container-fluid  my-5 py-2" data-parallax="scroll" >
        <div className="container py-2" >
          <div className="row g-5 align-items-center" >
            <div className="col-lg-1 "></div>
            <div className="col-lg-10 wow fadeIn" data-wow-delay="0.5s" >
              <div className='row bg-dark py-4 px-3' style={{ borderRadius: "13px", border: "5px solid #FF6F0F", boxShadow: "0px 0px 30px 10px black" }}>
                <div className="h-100 bg-dark "  >
                  <div className="row g-3">

                    

                    <div className="col-12">
                      <div className="form-floating">
                        <input type="text" className="form-control bg-light border-0" id="name" onChange={e => setName(e.target.value)} required />
                        <label htmlFor="email"> Full Name </label>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-floating">
                        <input type="mail" className="form-control bg-light border-0" id="email" onChange={e => setEmail(e.target.value)} required />
                        <label htmlFor="email"> Email ID</label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-floating">
                        <input type="text" className="form-control bg-light border-0" id="mobno" onChange={e => setMobNo(e.target.value)} required />
                        <label htmlFor="mobno"> WhatsApp Registered Mobile number</label>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-floating">
                        <select defaultValue={searchParams.get('type')}  className="form-select bg-light border-0" id="courseType"  onChange={e => setCourseType(e.target.value)} required disabled>
                        <option >Choose</option>
                        <option  value='Short Term'>Short Term</option>
                        <option  value='Long Term'>Long Term</option>
                        <option value='Daily Classes'>Daily Classes</option>
                        <option value='Residential Courses'>Residential Courses</option>
                        {searchParams.get('type')?<option value={searchParams.get('type')}>{searchParams.get('type')}</option>:""}
                        </select>
                        <label htmlFor="courseType">Course Type</label>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-floating">
                        <select defaultValue={searchParams.get('name')} className="form-select bg-light border-0" id="language"  onChange={e => setCourseName(e.target.value)} required disabled>
                        <option disabled>Choose</option>
                        {searchParams.get('name')?<option value={searchParams.get('name')}>{searchParams.get('name')}</option>:""}
                        
                        </select>
                        <label htmlFor="language">Course</label>
                      </div>
                    </div>
                    
                    <div className="col-12">
                      <div className="form-floating">
                        <select className="form-select bg-light border-0" id="language"  onChange={e => setLanguage(e.target.value)} required >
                        <option disabled selected>Choose</option>
                        <option value="English">English</option>
                        <option value="Hindi">Hindi (हिन्दी)</option>
                        <option value="Kannada">Kannada (ಕನ್ನಡ)</option>
                        <option value="Malayalam">Malayalam (മലയാളം)</option>
                        <option value="Telugu">Telugu (తెలుగు)</option>
                        <option value="Other">Other</option>
                        </select>
                        <label htmlFor="language">Prefered Language</label>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-floating">
                        <textarea className="form-control" id="address" onChange={e => setAddress(e.target.value)} style={{ height: 130 }} defaultValue={""} />
                        <label htmlFor="message">Address</label>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-floating">
                        <textarea className="form-control" onChange={e => setComment(e.target.value)} style={{ height: 130 }} defaultValue={""} />
                        <label htmlFor="message">Comment (if any)</label>
                      </div>
                    </div>

                    
                    <div className="col-12 text-center">
                      <button onClick={register} className="btn btn-outline-primary py-2 px-5 text-center" style={{ fontSize: "22px" }}>
                        Submit
                      </button>
                    </div>

                    {<Alert showAlart={showAlart} type={alertMessageType} userMessage={alertMessage} updateShowAlert={updateShowAlert} alertCategory={alertCategory} />}


                  </div>
                </div>
              </div >
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </div>

      {/* CoursesRegister End */}
      <Footer />

    </div>
  )
}