import React from 'react'

export default function Donate() {
    function initDonation(){
        alert('Developement in progress..');
    }
  return (
    <div id="donate">
        {/* Donate Start */}
        <div  className="container-fluid  my-5 py-4 bg-dark"  style={{background:"linear-gradient( rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0.5)100%), url('img/Radha-Krishna-Hd-Wallpaper-for-Desktop-Images.jpg')",backgroundRepeat: "no-repeat",backgroundSize:'cover',backgroundAttachment: "fixed"}}>
                    <div className="container ">
                        <div className="row g-5 py-5 align-items-center">
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                                <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3 display-5">Donate Now</div>
                                <h1 className="display-6 text-white mb-5">Krishna gives gives gives and forgives... Man gets gets gets and forgets...</h1>
                                <p className="text-white-50 mb-0">The  Lord is the bestower of all benedictions. If someone gives something to the Lord, he is not the loser, he is the gainer by a million times</p>
                            </div>
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                                <div className="h-100 bg-white p-5">
                                   
                                        <div className="row g-3">
                                            <div className="col-12">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control bg-light border-0" id="name" placeholder="Your Name" />
                                                    <label htmlFor="name">Your Name</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-floating">
                                                    <input type="email" className="form-control bg-light border-0" id="email" placeholder="Your Email" />
                                                    <label htmlFor="email">Your Email</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="btn-group d-flex justify-content-around">
                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio1" defaultChecked />
                                                    <label className="btn btn-light py-3" htmlFor="btnradio1">&#8377; 10</label>
                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio2" />
                                                    <label className="btn btn-light py-3" htmlFor="btnradio2">&#8377; 20</label>
                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio3" />
                                                    <label className="btn btn-light py-3" htmlFor="btnradio3">&#8377; 30</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button className="btn btn-primary px-5" style={{ height: 60 }} onClick={initDonation}>
                                                    Donate Now
                    <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                                        <i className="fa fa-arrow-right" />
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Donate End */}
    </div>
  )
}
