import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from '../../components/general/common/NavMenu'
import Footer from '../../components/general/common/Footer'
import {Home} from '../../pages/general/Home';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        {/* <NavMenu/> */}
        <div tag="main">
          {this.props.children}
        </div>
 
        {/* <Footer/> */}
      </div>
    );
  }
}
