import React from 'react'
import { Outlet, Link } from "react-router-dom";
export default function About() {
  return (
    <div id="about"  >
         {/* About Start */}
         <section className="container-xxl py-4">
                    <div className="container">
                        <div className="row g-5 py-5">
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="position-relative overflow-hidden h-100" >
                                    <img className="position-absolute w-100 h-100 pt-5 pe-5" src="img/slider/Radha-Krishna.jpg" alt="" style={{ objectFit: 'cover' }} />
                                    <img className="position-absolute top-0 end-0 bg-white ps-2 pb-2" src="img/Prabhupad.webp" alt="" style={{ width: 200, height: 200 }} />
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="h-100">
                                    <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3 display-5">About Us</div>
                                    <h1 className="display-6 mb-5">We Help People In Need Around The World</h1>
                                    <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4">
                                        <p className="text-dark mb-2" style={{textAlign:'justify'}}>Hare Krishna! Welcome to ISKCON Chitrakoot (Sri Sri Radha Gopinath Temple) – one of the most frequented spiritual landmarks in South Chitrakoot. ISKCON Chitrakoot is home to a loving community of practicing Hare Krishna devotees and is dedicated to the practice of bhakti-yoga or loving service to Krishna, the Supreme Person (God). We are a branch of the International Society for Krishna Consciousness (ISKCON), a worldwide spiritual movement founded by His Divine Grace A.C. Bhaktivedanta Swami Prabhupada, in New York City in 1966.</p>
                                        <span className="text-primary">Swami Prabhupada, Founder</span>
                                    </div>
                                    <p className="mb-5" style={{textAlign:'justify'}}>We can’t wait to see you all once again come and feel blessed seeing the main Darshan, go-around the complex seeing the Laxmi Narayan temple, the Vrindavan forest and the diaroma exhibits. Visiting for any of the daily aratis or joining us for the numerous festivals we celebrate. We also conduct a variety of courses, seminars and workshops. In fact, there’s something for everyone – the kids, the youth, the families. We also invite you to dine at our international restaurant, celebrate your occasion at the temple or join us for any of our tours. Together, let’s enhance the spiritual dimension of our lives!</p>
                                    <Link className="btn btn-primary py-2 px-3 me-3" to="#">
                                        Learn More
              <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                            <i className="fa fa-arrow-right" />
                                        </div>
                                    </Link>
                                    <Link className="btn btn-outline-primary py-2 px-3" to="#contactus">
                                        Contact Us
              <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                            <i className="fa fa-arrow-right" />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* About End */}
    </div>
  )
}
