
import Footer from '../../components/general/common/Footer'
import {NavMenu} from '../../components/general/common/NavMenu'
import React, { useState, useEffect } from 'react'
import callMyApi from '../../ApiCaller';
import { Link } from 'react-router-dom';
import ContactUs from '../../components/general/ContactUs';


export default function Courses(){
  
    return (
      <div>
<NavMenu/>

   {/* Page Header Start */}
   <div className="container-fluid page-header mb-5 wow fadeIn" data-wow-delay="0.1s">
  <div className="container text-center">
    <h1 className="display-4 text-white animated slideInDown mb-4">Contact Us</h1>
    <nav aria-label="breadcrumb animated slideInDown">
      <ol className="breadcrumb justify-content-center mb-0">
        <li className="breadcrumb-item"><Link className="text-white" to="#">Home</Link></li>
        {/* <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li> */}
        <li className="breadcrumb-item text-primary active" aria-current="page">Contact Us</li>
      </ol>
    </nav>
  </div>
</div>
{/* Page Header End */}

<ContactUs/>
<Footer/>

      </div>
    )
  }

